var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "countdown" },
    [
      _vm.isOnCart
        ? [
            _vm.hasIcon
              ? _c("span", { staticClass: "icon-hourglass" })
              : _vm._e(),
            _vm._v(
              " " +
                _vm._s(_vm.remainDays) +
                " " +
                _vm._s(_vm.$tc("countDown.days", _vm.remainDays)) +
                " · " +
                _vm._s(_vm.remainHours) +
                ":" +
                _vm._s(_vm.remainMinutes) +
                ":" +
                _vm._s(_vm.remainSeconds) +
                " "
            )
          ]
        : [
            _vm.daysVisible
              ? _c(
                  "div",
                  {
                    staticClass: "countdown__item",
                    attrs: { "data-test-id": "desktop-days" }
                  },
                  [
                    _c("span", { staticClass: "remain" }, [
                      _vm._v(_vm._s(_vm.remainDays))
                    ]),
                    _c("span", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.$tc("countDown.days", _vm.remain.days)))
                    ])
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "countdown__item" }, [
              _c("span", { staticClass: "remain" }, [
                _vm._v(_vm._s(_vm.remainHours))
              ]),
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$tc("countDown.hours", _vm.remain.hours)))
              ])
            ]),
            _c("div", { staticClass: "countdown__item" }, [
              _c("span", { staticClass: "remain" }, [
                _vm._v(_vm._s(_vm.remainMinutes))
              ]),
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$tc("countDown.minutes", _vm.remain.minutes)))
              ])
            ]),
            _vm.secondsVisible
              ? _c(
                  "div",
                  {
                    staticClass: "countdown__item",
                    attrs: { "data-test-id": "desktop-seconds" }
                  },
                  [
                    _c("span", { staticClass: "remain" }, [
                      _vm._v(_vm._s(_vm.remainSeconds))
                    ]),
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(_vm.$tc("countDown.seconds", _vm.remain.seconds))
                      )
                    ])
                  ]
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info-update" }, [
    _c("div", { staticClass: "info-update__container" }, [
      _vm.otpAccountId
        ? _c(
            "div",
            { staticClass: "container__body" },
            [
              _c("h2", { staticClass: "body__title" }, [
                _vm._v(" " + _vm._s(_vm.titleText) + " ")
              ]),
              _c("p", { staticClass: "body__help" }, [
                _vm._v(" " + _vm._s(_vm.helpText) + " ")
              ]),
              _c(
                "div",
                { staticClass: "body__info-selection" },
                _vm._l(_vm.availableInfo, function(info, idx) {
                  return _c(
                    "span",
                    {
                      key: "info-" + idx,
                      staticClass: "info-selection__option"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedInfo,
                            expression: "selectedInfo"
                          }
                        ],
                        staticClass: "option__input",
                        attrs: { id: "info-" + idx, type: "radio" },
                        domProps: {
                          value: info.value,
                          checked: _vm._q(_vm.selectedInfo, info.value)
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedInfo = info.value
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "option__label",
                          attrs: { for: "info-" + idx }
                        },
                        [_vm._v(_vm._s(info.display))]
                      )
                    ]
                  )
                }),
                0
              ),
              _vm.contactError
                ? _c("AppError", {
                    staticClass: "body__error",
                    attrs: { error: { contactError: _vm.contactError } }
                  })
                : _vm._e(),
              _c(
                "AppButton",
                {
                  attrs: { disabled: _vm.isSaving },
                  on: { click: _vm.handleContactUpdate }
                },
                [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppButton",
    {
      staticClass: "link",
      attrs: {
        type: "link",
        "icon-prefix": _vm.iconPrefix,
        "icon-suffix": _vm.iconSuffix
      },
      nativeOn: {
        click: function($event) {
          return _vm.onClick.apply(null, arguments)
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
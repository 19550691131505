var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CheckoutLinkFooter", {
    ref: "footer",
    class: { opened: _vm.isDetailVisible },
    attrs: {
      "action-text": _vm.newActionText,
      "action-amount": _vm.price,
      "action-disabled": _vm.disabledFooterAction,
      "has-icon": _vm.displayIcon,
      "has-amount": _vm.displayAmount
    },
    on: { click: _vm.onMainButtonClick }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-payment" }, [
    _vm.displayEditMode
      ? _c("div", { staticClass: "user-payment__edit-mode" }, [
          _c("div", { staticClass: "edit-mode__title" }, [
            _c(
              "div",
              { staticClass: "title__text" },
              [
                _c("font-awesome-icon", {
                  staticClass: "title__icon",
                  attrs: { icon: "lock" }
                }),
                _c("span", [_vm._v(_vm._s(_vm.editModeTitle))])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "title__cards" },
              [
                _c("IconCreditCardVisa", { staticClass: "cards__icon" }),
                _c("IconCreditCardAmex", { staticClass: "cards__icon" }),
                _c("IconCreditCardMastercard", { staticClass: "cards__icon" })
              ],
              1
            )
          ]),
          _vm.showStripeElements
            ? _c("div", { staticClass: "edit-mode__form" }, [
                _c("div", { attrs: { id: "payment" } })
              ])
            : _c(
                "div",
                { staticClass: "edit-mode__form" },
                [
                  _c("FormCardInput", {
                    attrs: {
                      "data-test-id": "card-number-input",
                      "has-external-error":
                        _vm.showErrors && !!_vm.errors.creditCard,
                      "show-errors": _vm.showErrors,
                      "existing-card": _vm.creditCard
                    },
                    on: {
                      change: function($event) {
                        return _vm.onNewPaymentMethodChange(
                          "creditCard",
                          $event
                        )
                      },
                      "validity-change": _vm.onValidityChange
                    }
                  }),
                  _c("FormInput", {
                    staticClass: "form__zip-code",
                    attrs: {
                      "data-test-id": "billing-zip-code-input",
                      value: _vm.creditCard.zipCode,
                      label: _vm.$t("paymentMethod.billingZipCode.label"),
                      placeholder: _vm.$t(
                        "paymentMethod.billingZipCode.placeholder"
                      ),
                      error: _vm.errors.zipCode,
                      "show-errors": _vm.showErrors
                    },
                    on: {
                      "value-changed": function($event) {
                        return _vm.onNewPaymentMethodChange(
                          "billingZipCode",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              ),
          _vm.cancelAllowed
            ? _c("div", { staticClass: "edit-mode__cancel" }, [
                _c(
                  "button",
                  {
                    attrs: { type: "button" },
                    on: { click: _vm.toggleEditMode }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("checkoutLink.checkout.cancel")) + " "
                    )
                  ]
                )
              ])
            : _vm._e()
        ])
      : _c(
          "div",
          { staticClass: "user-payment__display" },
          [
            _vm.displayTitle
              ? _c(
                  "p",
                  { staticClass: "display__title" },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "lock" } }),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("checkout.secureCheckout")))
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _c("CreditCardDropdown", {
              class: { "has-funnel": _vm.hasFunnel },
              attrs: {
                "is-material": _vm.isMaterial,
                "inside-modal": _vm.insideModal,
                label: _vm.$t("checkout.summary.payment"),
                "mask-numbers": _vm.insideModal || _vm.isMobile,
                "auto-select-first": !_vm.displayEditMode,
                "add-option": _vm.hasEmptyOption,
                "add-option-label": _vm.$t("checkout.summary.addPaymentCard"),
                disabled: _vm.disabledDropdown,
                value: _vm.selectedCreditCard,
                options: _vm.mappedCreditCards
              },
              on: {
                "first-value-selected": _vm.onSelectedPaymentMethodChange,
                "value-changed": _vm.onSelectedPaymentMethodChange,
                "add-option": _vm.toggleEditMode
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
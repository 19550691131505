var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.productsImagesWithBackup.length === 1
    ? _c(
        "div",
        { staticClass: "products-images-solo" },
        [
          _c("ProductOptimizedImage", {
            staticClass: "products-images-solo__image",
            attrs: {
              alt: "product image",
              src: _vm.productsImagesWithBackup[0],
              width: _vm.imageWidth
            }
          })
        ],
        1
      )
    : _vm.productsImagesWithBackup.length === 2
    ? _c(
        "div",
        { staticClass: "products-images-duo" },
        [
          _c("ProductOptimizedImage", {
            staticClass: "products-images-duo__image",
            attrs: {
              alt: "product image",
              src: _vm.productsImagesWithBackup[0],
              width: _vm.imageWidth
            }
          }),
          _c("ProductOptimizedImage", {
            staticClass: "products-images-duo__image",
            attrs: {
              alt: "product image",
              src: _vm.productsImagesWithBackup[1],
              width: _vm.imageWidth
            }
          })
        ],
        1
      )
    : _vm.productsImagesWithBackup.length >= 3
    ? _c(
        "div",
        { staticClass: "products-images-trio" },
        [
          _c("ProductOptimizedImage", {
            staticClass: "products-images-trio__image",
            attrs: {
              alt: "product image",
              src: _vm.productsImagesWithBackup[0],
              width: _vm.imageWidth
            }
          }),
          _c(
            "div",
            { staticClass: "products-images-trio__vertical-display" },
            [
              _c("ProductOptimizedImage", {
                staticClass: "vertical-display__image",
                attrs: {
                  alt: "product image",
                  src: _vm.productsImagesWithBackup[1],
                  width: _vm.imageWidth
                }
              }),
              _vm.productsImagesWithBackup.length === 3
                ? _c("ProductOptimizedImage", {
                    staticClass: "vertical-display__image",
                    attrs: {
                      alt: "product image",
                      src: _vm.productsImagesWithBackup[2],
                      width: _vm.imageWidth
                    }
                  })
                : _c(
                    "div",
                    { staticClass: "vertical-display__counter" },
                    [
                      _c("span", { staticClass: "counter__text" }, [
                        _vm._v(
                          " " + _vm._s(_vm.remainingProductsNumberInCart) + " "
                        )
                      ]),
                      _c("ProductOptimizedImage", {
                        staticClass: "counter__image",
                        attrs: {
                          alt: "product image",
                          src: _vm.productsImagesWithBackup[2],
                          width: _vm.imageWidth
                        }
                      })
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "not-found" }, [
    _c("div", { staticClass: "not-found__content" }, [
      _c("div", { staticClass: "content__error" }, [
        _c("div", { staticClass: "error__title" }, [
          _vm._v(_vm._s(_vm.$t("error.page.title")))
        ]),
        _c("div", { staticClass: "error__code" }, [
          _vm._v(_vm._s(_vm.$t("error.page.404.code")))
        ]),
        _c("div", { staticClass: "error__content" }, [
          _vm._v(_vm._s(_vm.$t("error.page.404.content")))
        ]),
        _c("p", { staticClass: "error__description" }, [
          _vm._v(" " + _vm._s(_vm.$t("error.page.404.description")) + " ")
        ])
      ]),
      _c(
        "button",
        {
          staticClass: "content__action",
          attrs: { type: "button" },
          on: { click: _vm.goToRoute }
        },
        [_vm._v(" " + _vm._s(_vm.$t("error.page.action")) + " ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeOptions,
          expression: "closeOptions"
        }
      ],
      staticClass: "user-phone"
    },
    [
      _c(
        "div",
        {
          ref: "phone-field",
          staticClass: "user-phone__field",
          class: {
            "user-phone__field--focus": _vm.isFocused,
            "user-phone__field--filled": _vm.isFilled,
            "user-phone__field--error": _vm.hasError
          },
          attrs: { "data-test-id": "user-phone" }
        },
        [
          _c(
            "div",
            {
              staticClass: "field__country-selector",
              on: { click: _vm.openOptions }
            },
            [
              _c("div", {
                staticClass: "country-selector__flag",
                class: _vm.selectedCountryClass,
                attrs: { "data-test-id": "form-phone-selected" }
              }),
              _c("font-awesome-icon", {
                staticClass: "country-selector__arrow",
                attrs: { icon: "sort-down" }
              })
            ],
            1
          ),
          _c("div", { staticClass: "field__input" }, [
            _c("label", [
              _c("input", {
                staticClass: "material-input",
                attrs: {
                  placeholder: " ",
                  "data-cy": "user-phone-input",
                  "data-test-id": "user-phone-input",
                  disabled: _vm.disabled
                },
                domProps: { value: _vm.phone },
                on: {
                  focus: _vm.setFocus,
                  blur: _vm.removeFocus,
                  input: _vm.onNewPhoneValueChange
                }
              }),
              _c("span", { staticClass: "material-label" }, [
                _vm._v(_vm._s(_vm.$t("form.phoneField.placeholder")))
              ])
            ])
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "user-phone__countries",
          class: { open: _vm.isOpen },
          style: { top: _vm.top(), left: _vm.left(), width: _vm.width() }
        },
        [
          _c(
            "div",
            {
              staticClass: "countries__search",
              class: { "countries__search--focus": _vm.isSearchFocused }
            },
            [
              _c(
                "span",
                { staticClass: "search__prefix" },
                [_c("font-awesome-icon", { attrs: { icon: "search" } })],
                1
              ),
              _c("input", {
                ref: "countrySearch",
                staticClass: "search__input",
                attrs: { placeholder: _vm.$t("form.phoneField.search") },
                on: {
                  keyup: _vm.searchCountry,
                  focus: _vm.setSearchFocus,
                  blur: _vm.removeSearchFocus
                }
              })
            ]
          ),
          _c(
            "div",
            { staticClass: "countries__container-items" },
            _vm._l(_vm.filteredCountries || _vm.countries, function(country) {
              return _c(
                "button",
                {
                  key: country.iso2,
                  staticClass: "countries__item",
                  class: { active: _vm.isSelected(country) },
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.onSelect(country)
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass: "item__flag",
                    class: _vm.countryClass(country)
                  }),
                  _c("span", { staticClass: "item__name" }, [
                    _vm._v(_vm._s(country.name))
                  ]),
                  _c("span", { staticClass: "item__code" }, [
                    _vm._v("+" + _vm._s(country.dialCode))
                  ])
                ]
              )
            }),
            0
          )
        ]
      ),
      _vm.hasError
        ? _c(
            "span",
            {
              staticClass: "user-phone__error-message",
              attrs: { "data-cy": "user-phone-error" }
            },
            [_vm._v(" " + _vm._s(_vm.$t("form.phoneField.error")) + " ")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.buttonClass,
      attrs: { type: "button", disabled: _vm.disabled }
    },
    [_c("font-awesome-icon", { attrs: { icon: _vm.name } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "presell-display" },
    [
      _vm.details.isVisible
        ? _c("FunnelProductDetails", {
            attrs: { product: _vm.details.product, tab: _vm.details.tab },
            on: { click: _vm.onCloseModal, "add-to-cart": _vm.addToCart }
          })
        : _vm._e(),
      _c("div", { staticClass: "presell" }, [
        _c(
          "div",
          { staticClass: "presell__container" },
          [
            _c(
              "div",
              { staticClass: "container__main" },
              [
                _c("div", { staticClass: "main__header" }, [
                  _vm.siteLogo
                    ? _c("img", {
                        staticClass: "header__logo",
                        attrs: { src: _vm.siteLogo, alt: "logo" }
                      })
                    : _vm._e()
                ]),
                _c(
                  "div",
                  { staticClass: "main__content" },
                  [
                    _c(
                      "p",
                      { staticClass: "content__countdown" },
                      [
                        _c("FunnelCounter", {
                          staticClass: "countdown__timer",
                          attrs: {
                            "initial-duration": _vm.timerRemaining,
                            "internal-timer": true
                          },
                          on: { elapsed: _vm.onElapsed }
                        })
                      ],
                      1
                    ),
                    _c("p", { staticClass: "content__title" }, [
                      _vm._v(" " + _vm._s(_vm.getFunnelTitle) + " ")
                    ]),
                    _c("p", { staticClass: "content__subtitle" }, [
                      _vm._v(" " + _vm._s(_vm.getFunnelSubtitle) + " ")
                    ]),
                    _c("PostSellContent")
                  ],
                  1
                ),
                _c("FunnelProductDisplay", {
                  staticClass: "main__products",
                  on: { "view-details": _vm.onToggleDetails }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "container__footer" },
              [
                _c("LayoutBrand", { staticClass: "footer__brand" }),
                _c("CheckoutLinkMainFooter", {
                  staticClass: "footer__action",
                  attrs: { "action-disabled": _vm.disableMainFooterPayment },
                  on: { pay: _vm.handlePay }
                })
              ],
              1
            ),
            _vm.isMobile
              ? _c("BottomBar", { attrs: { presell: true } })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
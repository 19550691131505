var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-list-removable" },
    [
      _vm._l(_vm.items, function(item, idx) {
        return _c("CheckoutProductInOffer", {
          key: item.variantId + "-" + idx,
          staticClass: "product-list-removable__item",
          attrs: { product: item }
        })
      }),
      _vm._l(_vm.removableItems, function(item, idx) {
        return _c(
          "div",
          {
            key: "removable_" + item.variantId + "-" + idx,
            staticClass: "product-list-removable__removable-item"
          },
          [
            _c("div", { staticClass: "removable-item__title" }, [
              _vm._v(" " + _vm._s(item.label) + " ")
            ]),
            _c(
              "div",
              { staticClass: "removable-item__price" },
              [
                _c("CheckoutPrice", {
                  staticClass: "rs__price",
                  attrs: { amount: item.price, currency: item.currency }
                })
              ],
              1
            ),
            _c("AppIconButton", {
              staticClass: "removable-item__remove",
              attrs: { name: "times", size: "small" },
              nativeOn: {
                click: function($event) {
                  return _vm.onRemove(item.variantId)
                }
              }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 246 184",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0)" } }, [
        _c("rect", {
          attrs: {
            width: "103.254",
            height: "103.254",
            transform:
              "matrix(0.855249 0.518218 -0.855249 0.518218 123 58.9836)",
            fill: "#393151"
          }
        }),
        _c("rect", {
          attrs: {
            width: "103.254",
            height: "103.254",
            transform: "matrix(0.855159 0.518366 -0.855159 0.518366 123 17)",
            fill: "#393151"
          }
        }),
        _c("path", {
          attrs: {
            d: "M123 17L34.7015 70.5234L78.8509 95.2852L167.15 43.7617L123 17Z",
            fill: "#FAF8FF"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M123 124.016L211.308 70.508L167.154 43.754L78.8462 95.2624L123 124.016Z",
            fill: "#FAF8FF"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M34.6922 112.492L123 166L123 123.959L34.6921 70.4502L34.6922 112.492Z",
            fill: "#E3DDED"
          }
        }),
        _c("path", {
          attrs: {
            d: "M123 166L211.308 112.492L211.308 70.4502L123 123.958L123 166Z",
            fill: "#C8C0D8"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M166.33 122.626L206.677 98.1789L206.677 78.9707L166.33 103.418L166.33 122.626Z",
            fill: "white"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M203.864 87.3312L188.896 96.6981V97.4638L203.864 88.0969V87.3312Z",
            fill: "#393151"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M203.864 89.997L188.897 99.3636L188.896 100.13L203.864 90.7627V89.997Z",
            fill: "#393151"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M203.864 92.6628L188.897 102.029L188.896 102.795L203.864 93.4286V92.6628Z",
            fill: "#393151"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M188.897 104.693L188.898 105.46L195.025 101.579V100.811L188.897 104.693Z",
            fill: "#393151"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M203.864 84.4687L188.896 93.8356V94.6013L203.864 85.2344V84.4687Z",
            fill: "#393151"
          }
        }),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d:
              "M181.714 109.794L180.627 110.479C180.392 106.416 180.317 102.466 180.382 98.6404L181.468 97.9555C181.404 101.781 181.479 105.732 181.714 109.794ZM179.572 99.1511L178.763 99.6609C178.709 103.481 178.795 107.425 179.039 111.48L179.839 110.976C179.597 106.919 179.515 102.973 179.572 99.1511ZM177.966 100.163L178.328 99.9349C178.264 103.76 178.339 107.711 178.574 111.774L178.212 112.002C177.977 107.939 177.902 103.988 177.966 100.163ZM176.88 100.848L176.526 101.072C176.461 104.897 176.535 108.848 176.77 112.911L177.125 112.687C176.89 108.624 176.815 104.673 176.88 100.848ZM175.73 101.573L176.196 101.279C176.128 105.106 176.199 109.059 176.43 113.125L175.943 113.432C175.719 109.361 175.654 105.404 175.73 101.573ZM174.971 102.051L173.747 102.823C173.661 106.658 173.716 110.622 173.932 114.7L175.181 113.913C174.957 109.841 174.894 105.882 174.971 102.051ZM173.115 103.221L173.38 103.055C173.298 106.888 173.355 110.85 173.574 114.926L173.299 115.099C173.084 111.021 173.029 107.057 173.115 103.221ZM182.33 109.406C182.073 105.361 181.974 101.425 182.014 97.6112L182.345 97.403C182.308 101.215 182.41 105.149 182.671 109.191L182.33 109.406ZM182.972 109.001L183.461 108.693C183.206 104.646 183.109 100.709 183.151 96.8947L182.647 97.2122C182.61 101.024 182.712 104.958 182.972 109.001ZM184.059 108.316C183.798 104.273 183.697 100.339 183.734 96.5273L184.534 96.023C184.507 99.83 184.619 103.758 184.889 107.793L184.059 108.316ZM172.664 115.5C172.441 111.427 172.378 107.468 172.456 103.637L171.825 104.035C171.746 107.867 171.808 111.826 172.03 115.899L172.664 115.5ZM171.755 116.073C171.536 111.997 171.478 108.035 171.56 104.202L171.086 104.501C171.021 108.326 171.096 112.277 171.331 116.34L171.755 116.073ZM170.788 116.682C170.553 112.619 170.478 108.669 170.542 104.843L169.999 105.186C169.934 109.011 170.01 112.962 170.244 117.025L170.788 116.682Z",
            fill: "#393151"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M177.604 100.391L177.423 100.506C177.358 104.331 177.434 108.282 177.668 112.345L177.85 112.23C177.615 108.167 177.54 104.217 177.604 100.391Z",
            fill: "#393151"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M159.92 39.3706L172.444 46.9592L84.5 100L71.9762 92.4114L159.92 39.3706Z",
          fill: "#6028FF"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M84.5 99.9999L72.0807 92.4746L72 105.112L84.4193 112.637L84.5 99.9999Z",
          fill: "#4B18DC"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M107.504 79.6844L105.004 81.1844L104.28 80.5168L106.78 79.0168L107.504 79.6844Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M78.8578 105.894L78.9037 108.773L78.1161 108.345L78.0702 105.467L78.8578 105.894Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M143.258 57.6844L140.758 59.1844L140.034 58.5168L142.534 57.0168L143.258 57.6844Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M110.448 73.9834C110.998 73.6467 111.56 73.5105 112.132 73.5749C112.704 73.6322 113.273 73.9022 113.839 74.3849L116.169 76.3727L115.343 76.8778L114.859 76.465C114.925 76.6788 114.905 76.8873 114.799 77.0904C114.692 77.2865 114.499 77.4701 114.219 77.6414C113.939 77.8126 113.644 77.9201 113.335 77.9638C113.021 78.0033 112.716 77.9825 112.421 77.9014C112.125 77.8133 111.863 77.6718 111.635 77.477C111.278 77.1722 111.116 76.8531 111.15 76.5198C111.183 76.1795 111.469 75.8452 112.005 75.517L112.971 74.9263L112.912 74.8755C112.634 74.6384 112.341 74.5048 112.033 74.4749C111.73 74.442 111.425 74.5198 111.117 74.7081C110.907 74.8365 110.734 74.9931 110.598 75.178C110.467 75.36 110.394 75.5467 110.378 75.7381L109.357 75.37C109.394 75.1146 109.508 74.8668 109.699 74.6264C109.89 74.3861 110.14 74.1718 110.448 73.9834ZM113.723 76.9521C113.943 76.818 114.084 76.6551 114.148 76.4635C114.212 76.2648 114.179 76.0593 114.051 75.8472L113.589 75.4534L112.686 76.0056C112.182 76.3138 112.106 76.6182 112.459 76.9188C112.627 77.0627 112.824 77.1385 113.05 77.1462C113.275 77.1538 113.5 77.0891 113.723 76.9521Z",
          fill: "#FAF8FF"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M119.093 74.6608C118.705 74.8977 118.271 75.0362 117.789 75.0764C117.308 75.1165 116.827 75.0581 116.346 74.9012C115.861 74.7401 115.422 74.4923 115.03 74.1578C114.638 73.8233 114.374 73.472 114.239 73.1039C114.104 72.7359 114.102 72.385 114.231 72.0513C114.365 71.7148 114.629 71.4267 115.021 71.1869C115.389 70.9615 115.791 70.8323 116.225 70.7995C116.664 70.7637 117.102 70.8301 117.54 70.9985L117.285 71.7651C116.994 71.6741 116.722 71.6404 116.469 71.664C116.216 71.6806 115.994 71.7474 115.803 71.8644C115.476 72.0642 115.32 72.3271 115.333 72.6531C115.342 72.9749 115.535 73.2967 115.912 73.6184C116.289 73.9402 116.697 74.1343 117.135 74.2006C117.573 74.2599 117.958 74.1882 118.289 73.9856C118.48 73.8686 118.611 73.7235 118.68 73.5502C118.749 73.3699 118.746 73.1686 118.669 72.9463L119.758 72.891C119.911 73.2335 119.93 73.5602 119.813 73.8712C119.697 74.175 119.457 74.4383 119.093 74.6608Z",
          fill: "#FAF8FF"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M121.016 67.5856L126.436 72.209L125.561 72.744L123.633 71.0992C123.696 71.5838 123.476 71.9802 122.972 72.2884C122.608 72.511 122.197 72.6388 121.739 72.6718C121.286 72.7019 120.823 72.6356 120.352 72.473C119.886 72.3075 119.449 72.0512 119.042 71.704C118.64 71.3611 118.367 71.0084 118.223 70.646C118.079 70.2837 118.064 69.9435 118.18 69.6254C118.296 69.3074 118.536 69.0371 118.9 68.8145C119.157 68.6576 119.437 68.5551 119.741 68.5071C120.05 68.4563 120.371 68.4671 120.705 68.5395L120.184 68.095L121.016 67.5856ZM122.274 71.5489C122.596 71.352 122.747 71.092 122.729 70.7688C122.711 70.4457 122.515 70.1253 122.143 69.8078C121.771 69.4902 121.366 69.2983 120.927 69.2319C120.489 69.1656 120.109 69.2309 119.787 69.4278C119.465 69.6247 119.313 69.8847 119.332 70.2079C119.355 70.5282 119.552 70.8471 119.924 71.1647C120.297 71.4822 120.7 71.6756 121.133 71.7448C121.571 71.8111 121.952 71.7459 122.274 71.5489Z",
          fill: "#FAF8FF"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M125.78 64.6725L129.755 68.0639L128.922 68.5732L128.416 68.1414C128.455 68.3794 128.419 68.605 128.308 68.8181C128.192 69.0269 128.013 69.2055 127.771 69.3539C127.271 69.6593 126.731 69.7755 126.148 69.7026C125.566 69.6227 124.984 69.335 124.404 68.8397L122.133 66.9027L123.008 66.3676L125.152 68.1966C125.51 68.5015 125.852 68.6844 126.18 68.7455C126.508 68.7996 126.812 68.741 127.092 68.5697C127.404 68.3785 127.55 68.1405 127.528 67.8556C127.507 67.5637 127.302 67.2526 126.915 66.9223L124.905 65.2076L125.78 64.6725Z",
          fill: "#FAF8FF"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M126.926 63.972L127.8 63.4369L131.776 66.8282L130.901 67.3633L126.926 63.972Z",
          fill: "#FAF8FF"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M130.362 62.7206C130.198 62.2109 130.432 61.762 131.067 61.3739L131.953 62.1296C131.863 62.1626 131.788 62.1976 131.728 62.2347C131.387 62.443 131.228 62.6967 131.25 62.9958C131.267 63.2906 131.474 63.6073 131.871 63.9461L133.799 65.5909L132.924 66.126L128.949 62.7346L129.782 62.2253L130.362 62.7206Z",
          fill: "#FAF8FF"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M137.343 60.5449C137.408 60.5999 137.495 60.6811 137.605 60.7884L134.672 62.582C135.016 62.8004 135.383 62.918 135.771 62.9349C136.159 62.9448 136.514 62.8512 136.836 62.6543C137.247 62.4032 137.441 62.0735 137.419 61.6653L138.461 61.8675C138.507 62.1523 138.456 62.4198 138.308 62.6699C138.161 62.92 137.926 63.1435 137.604 63.3404C137.193 63.5915 136.745 63.7385 136.259 63.7816C135.772 63.8246 135.289 63.7676 134.809 63.6107C134.328 63.4467 133.891 63.1975 133.499 62.863C133.112 62.5327 132.847 62.1864 132.702 61.8241C132.557 61.4546 132.545 61.1059 132.666 60.7779C132.786 60.4499 133.03 60.1732 133.399 59.9478C133.763 59.7252 134.174 59.601 134.632 59.5751C135.09 59.5421 135.555 59.6105 136.026 59.7802C136.497 59.9428 136.936 60.1977 137.343 60.5449ZM134.143 60.5829C133.863 60.7541 133.715 60.9758 133.697 61.2481C133.68 61.5133 133.796 61.7836 134.047 62.0591L136.168 60.7621C135.843 60.5394 135.495 60.4103 135.125 60.3749C134.755 60.3395 134.428 60.4088 134.143 60.5829Z",
          fill: "#FAF8FF"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M110.448 73.9834C110.998 73.6467 111.56 73.5105 112.132 73.5749C112.704 73.6322 113.273 73.9022 113.839 74.3849L116.169 76.3727L115.343 76.8778L114.859 76.465C114.925 76.6788 114.905 76.8873 114.799 77.0904C114.692 77.2865 114.499 77.4701 114.219 77.6414C113.939 77.8126 113.644 77.9201 113.335 77.9638C113.021 78.0033 112.716 77.9825 112.421 77.9014C112.125 77.8133 111.863 77.6718 111.635 77.477C111.278 77.1722 111.116 76.8531 111.15 76.5198C111.183 76.1795 111.469 75.8452 112.005 75.517L112.971 74.9263L112.912 74.8755C112.634 74.6384 112.341 74.5048 112.033 74.4749C111.73 74.442 111.425 74.5198 111.117 74.7081C110.907 74.8365 110.734 74.9931 110.598 75.178C110.467 75.36 110.394 75.5467 110.378 75.7381L109.357 75.37C109.394 75.1146 109.508 74.8668 109.699 74.6264C109.89 74.3861 110.14 74.1718 110.448 73.9834ZM113.723 76.9521C113.943 76.818 114.084 76.6551 114.148 76.4635C114.212 76.2648 114.179 76.0593 114.051 75.8472L113.589 75.4534L112.686 76.0056C112.182 76.3138 112.106 76.6182 112.459 76.9188C112.627 77.0627 112.824 77.1385 113.05 77.1462C113.275 77.1538 113.5 77.0891 113.723 76.9521Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M119.093 74.6608C118.705 74.8977 118.271 75.0362 117.789 75.0764C117.308 75.1165 116.827 75.0581 116.346 74.9012C115.861 74.7401 115.422 74.4923 115.03 74.1578C114.638 73.8233 114.374 73.472 114.239 73.1039C114.104 72.7359 114.102 72.385 114.231 72.0513C114.365 71.7148 114.629 71.4267 115.021 71.1869C115.389 70.9615 115.791 70.8323 116.225 70.7995C116.664 70.7637 117.102 70.8301 117.54 70.9985L117.285 71.7651C116.994 71.6741 116.722 71.6404 116.469 71.664C116.216 71.6806 115.994 71.7474 115.803 71.8644C115.476 72.0642 115.32 72.3271 115.333 72.6531C115.342 72.9749 115.535 73.2967 115.912 73.6184C116.289 73.9402 116.697 74.1343 117.135 74.2006C117.573 74.2599 117.958 74.1882 118.289 73.9856C118.48 73.8686 118.611 73.7235 118.68 73.5502C118.749 73.3699 118.746 73.1686 118.669 72.9463L119.758 72.891C119.911 73.2335 119.93 73.5602 119.813 73.8712C119.697 74.175 119.457 74.4383 119.093 74.6608Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M121.016 67.5856L126.436 72.209L125.561 72.744L123.633 71.0992C123.696 71.5838 123.476 71.9802 122.972 72.2884C122.608 72.511 122.197 72.6388 121.739 72.6718C121.286 72.7019 120.823 72.6356 120.352 72.473C119.886 72.3075 119.449 72.0512 119.042 71.704C118.64 71.3611 118.367 71.0084 118.223 70.646C118.079 70.2837 118.064 69.9435 118.18 69.6254C118.296 69.3074 118.536 69.0371 118.9 68.8145C119.157 68.6576 119.437 68.5551 119.741 68.5071C120.05 68.4563 120.371 68.4671 120.705 68.5395L120.184 68.095L121.016 67.5856ZM122.274 71.5489C122.596 71.352 122.747 71.092 122.729 70.7688C122.711 70.4457 122.515 70.1253 122.143 69.8078C121.771 69.4902 121.366 69.2983 120.927 69.2319C120.489 69.1656 120.109 69.2309 119.787 69.4278C119.465 69.6247 119.313 69.8847 119.332 70.2079C119.355 70.5282 119.552 70.8471 119.924 71.1647C120.297 71.4822 120.7 71.6756 121.133 71.7448C121.571 71.8111 121.952 71.7459 122.274 71.5489Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M125.78 64.6725L129.755 68.0639L128.922 68.5732L128.416 68.1414C128.455 68.3794 128.419 68.605 128.308 68.8181C128.192 69.0269 128.013 69.2055 127.771 69.3539C127.271 69.6593 126.731 69.7755 126.148 69.7026C125.566 69.6227 124.984 69.335 124.404 68.8397L122.133 66.9027L123.008 66.3676L125.152 68.1966C125.51 68.5015 125.852 68.6844 126.18 68.7455C126.508 68.7996 126.812 68.741 127.092 68.5697C127.404 68.3785 127.55 68.1405 127.528 67.8556C127.507 67.5637 127.302 67.2526 126.915 66.9223L124.905 65.2076L125.78 64.6725Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M126.926 63.972L127.8 63.4369L131.776 66.8282L130.901 67.3633L126.926 63.972Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M130.362 62.7206C130.198 62.2109 130.432 61.762 131.067 61.3739L131.953 62.1296C131.863 62.1626 131.788 62.1976 131.728 62.2347C131.387 62.443 131.228 62.6967 131.25 62.9958C131.267 63.2906 131.474 63.6073 131.871 63.9461L133.799 65.5909L132.924 66.126L128.949 62.7346L129.782 62.2253L130.362 62.7206Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M137.343 60.5449C137.408 60.5999 137.495 60.6811 137.605 60.7884L134.672 62.582C135.016 62.8004 135.383 62.918 135.771 62.9349C136.159 62.9448 136.514 62.8512 136.836 62.6543C137.247 62.4032 137.441 62.0735 137.419 61.6653L138.461 61.8675C138.507 62.1523 138.456 62.4198 138.308 62.6699C138.161 62.92 137.926 63.1435 137.604 63.3404C137.193 63.5915 136.745 63.7385 136.259 63.7816C135.772 63.8246 135.289 63.7676 134.809 63.6107C134.328 63.4467 133.891 63.1975 133.499 62.863C133.112 62.5327 132.847 62.1864 132.702 61.8241C132.557 61.4546 132.545 61.1059 132.666 60.7779C132.786 60.4499 133.03 60.1732 133.399 59.9478C133.763 59.7252 134.174 59.601 134.632 59.5751C135.09 59.5421 135.555 59.6105 136.026 59.7802C136.497 59.9428 136.936 60.1977 137.343 60.5449ZM134.143 60.5829C133.863 60.7541 133.715 60.9758 133.697 61.2481C133.68 61.5133 133.796 61.7836 134.047 62.0591L136.168 60.7621C135.843 60.5394 135.495 60.4103 135.125 60.3749C134.755 60.3395 134.428 60.4088 134.143 60.5829Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M126.202 63.3487C126.413 63.372 126.599 63.3351 126.757 63.2381C126.921 63.1382 126.999 63.0103 126.992 62.8544C126.986 62.6914 126.9 62.54 126.736 62.4003C126.583 62.2691 126.402 62.1939 126.196 62.1749C125.984 62.1516 125.799 62.1885 125.641 62.2855C125.482 62.3825 125.403 62.5104 125.405 62.6692C125.402 62.8237 125.48 62.9688 125.639 63.1042C125.798 63.2397 125.985 63.3212 126.202 63.3487Z",
          fill: "#FAF8FF",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M146.202 51.9834C146.753 51.6467 147.314 51.5105 147.886 51.5749C148.458 51.6322 149.027 51.9022 149.593 52.3849L151.923 54.3727L151.097 54.8778L150.613 54.465C150.68 54.6788 150.66 54.8873 150.553 55.0904C150.447 55.2865 150.253 55.4701 149.973 55.6414C149.693 55.8126 149.399 55.9201 149.09 55.9638C148.775 56.0033 148.471 55.9825 148.175 55.9014C147.88 55.8133 147.618 55.6718 147.389 55.477C147.032 55.1722 146.87 54.8531 146.904 54.5198C146.938 54.1795 147.223 53.8452 147.76 53.517L148.725 52.9263L148.666 52.8755C148.388 52.6384 148.095 52.5048 147.788 52.4749C147.485 52.442 147.179 52.5198 146.871 52.7081C146.661 52.8365 146.489 52.9931 146.353 53.178C146.222 53.36 146.148 53.5467 146.132 53.7381L145.112 53.37C145.149 53.1146 145.263 52.8668 145.454 52.6264C145.645 52.3861 145.894 52.1718 146.202 51.9834ZM149.478 54.9521C149.697 54.818 149.839 54.6551 149.903 54.4635C149.966 54.2648 149.933 54.0593 149.805 53.8472L149.343 53.4534L148.44 54.0056C147.936 54.3138 147.861 54.6182 148.213 54.9188C148.382 55.0627 148.579 55.1385 148.804 55.1462C149.029 55.1538 149.254 55.0891 149.478 54.9521Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M154.847 52.6608C154.46 52.8977 154.025 53.0362 153.544 53.0764C153.062 53.1165 152.581 53.0581 152.101 52.9012C151.615 52.7401 151.177 52.4923 150.784 52.1578C150.392 51.8233 150.129 51.472 149.994 51.1039C149.859 50.7359 149.856 50.385 149.986 50.0513C150.12 49.7148 150.383 49.4267 150.775 49.1869C151.144 48.9615 151.545 48.8323 151.979 48.7995C152.418 48.7637 152.856 48.8301 153.294 48.9985L153.039 49.7651C152.748 49.674 152.476 49.6404 152.223 49.664C151.971 49.6806 151.748 49.7474 151.557 49.8644C151.23 50.0642 151.074 50.3271 151.088 50.6531C151.096 50.9749 151.289 51.2967 151.666 51.6184C152.044 51.9402 152.451 52.1343 152.89 52.2006C153.328 52.2599 153.712 52.1882 154.044 51.9856C154.235 51.8686 154.365 51.7235 154.435 51.5502C154.504 51.3699 154.5 51.1686 154.424 50.9463L155.512 50.891C155.666 51.2335 155.684 51.5602 155.568 51.8712C155.451 52.175 155.211 52.4383 154.847 52.6608Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M156.771 45.5856L162.191 50.209L161.316 50.744L159.387 49.0992C159.451 49.5838 159.23 49.9802 158.727 50.2884C158.363 50.511 157.952 50.6388 157.493 50.6718C157.04 50.7019 156.578 50.6356 156.107 50.473C155.641 50.3075 155.204 50.0512 154.797 49.704C154.395 49.3611 154.122 49.0084 153.977 48.646C153.833 48.2837 153.819 47.9435 153.935 47.6254C154.05 47.3074 154.29 47.0371 154.654 46.8145C154.911 46.6576 155.191 46.5551 155.496 46.5071C155.805 46.4563 156.126 46.4671 156.459 46.5395L155.938 46.095L156.771 45.5856ZM158.028 49.5489C158.35 49.352 158.502 49.092 158.483 48.7688C158.465 48.4457 158.27 48.1253 157.898 47.8078C157.525 47.4902 157.12 47.2983 156.682 47.2319C156.244 47.1656 155.863 47.2309 155.541 47.4278C155.22 47.6247 155.068 47.8847 155.086 48.2079C155.109 48.5282 155.307 48.8471 155.679 49.1647C156.051 49.4822 156.454 49.6756 156.888 49.7448C157.326 49.8111 157.706 49.7459 158.028 49.5489Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M161.534 42.6725L165.51 46.0639L164.677 46.5732L164.171 46.1414C164.209 46.3794 164.173 46.605 164.063 46.8181C163.947 47.0269 163.768 47.2055 163.525 47.3539C163.026 47.6593 162.485 47.7755 161.903 47.7026C161.32 47.6227 160.739 47.335 160.158 46.8397L157.888 44.9027L158.763 44.3676L160.907 46.1966C161.264 46.5015 161.607 46.6844 161.935 46.7455C162.262 46.7996 162.566 46.741 162.846 46.5697C163.159 46.3785 163.304 46.1405 163.283 45.8556C163.261 45.5637 163.057 45.2526 162.669 44.9223L160.659 43.2076L161.534 42.6725Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M162.68 41.972L163.555 41.4369L167.53 44.8282L166.655 45.3633L162.68 41.972Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M161.956 41.3487C162.168 41.372 162.353 41.3351 162.512 41.2381C162.675 41.1382 162.753 41.0103 162.747 40.8544C162.74 40.6914 162.655 40.54 162.491 40.4003C162.337 40.2691 162.157 40.1939 161.95 40.1749C161.739 40.1516 161.554 40.1885 161.395 40.2855C161.236 40.3825 161.158 40.5104 161.16 40.6692C161.157 40.8237 161.234 40.9688 161.393 41.1042C161.552 41.2397 161.74 41.3212 161.956 41.3487Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M83.0931 96.6609C82.7057 96.8978 82.2713 97.0363 81.7896 97.0764C81.3079 97.1166 80.8269 97.0582 80.3465 96.9013C79.8612 96.7401 79.4225 96.4923 79.0304 96.1578C78.6383 95.8234 78.3748 95.4721 78.2397 95.104C78.1047 94.7359 78.1019 94.385 78.2315 94.0514C78.3658 93.7148 78.6289 93.4267 79.0209 93.187C79.3895 92.9616 79.7909 92.8324 80.2251 92.7995C80.6638 92.7638 81.1021 92.8301 81.5399 92.9986L81.2848 93.7651C80.9939 93.6741 80.7221 93.6404 80.4694 93.6641C80.2165 93.6807 79.9943 93.7475 79.803 93.8645C79.4764 94.0642 79.3199 94.3271 79.3335 94.6532C79.3422 94.9749 79.5351 95.2967 79.9123 95.6185C80.2895 95.9403 80.6973 96.1343 81.1355 96.2007C81.5735 96.2599 81.9582 96.1883 82.2895 95.9856C82.4808 95.8686 82.6111 95.7235 82.6804 95.5503C82.7495 95.37 82.7458 95.1687 82.6695 94.9464L83.7584 94.8911C83.9115 95.2336 83.93 95.5603 83.8138 95.8712C83.6973 96.1751 83.457 96.4383 83.0931 96.6609Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M85.0168 89.5857L90.4364 94.2091L89.5615 94.7441L87.6334 93.0993C87.6967 93.5839 87.4764 93.9803 86.9725 94.2885C86.6085 94.5111 86.1975 94.6388 85.7394 94.6718C85.286 94.7019 84.8238 94.6357 84.3528 94.4731C83.8864 94.3076 83.4498 94.0513 83.0428 93.7041C82.6408 93.3611 82.3676 93.0085 82.2232 92.6461C82.0788 92.2837 82.0646 91.9435 82.1805 91.6255C82.2964 91.3075 82.5363 91.0372 82.9003 90.8146C83.157 90.6576 83.4374 90.5552 83.7417 90.5072C84.0506 90.4564 84.3717 90.4672 84.705 90.5396L84.1839 90.095L85.0168 89.5857ZM86.2739 93.549C86.5959 93.3521 86.7477 93.0921 86.7294 92.7689C86.7111 92.4457 86.5158 92.1254 86.1436 91.8078C85.7713 91.4903 85.3661 91.2983 84.9278 91.232C84.4895 91.1657 84.1094 91.2309 83.7874 91.4278C83.4654 91.6247 83.3136 91.8848 83.3319 92.208C83.3549 92.5283 83.5525 92.8472 83.9247 93.1647C84.297 93.4823 84.6999 93.6757 85.1335 93.7449C85.5718 93.8112 85.9519 93.7459 86.2739 93.549Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M89.7803 86.6726L93.7557 90.0639L92.9227 90.5733L92.4165 90.1414C92.4552 90.3795 92.4192 90.605 92.3084 90.8181C92.1927 91.027 92.0136 91.2056 91.7709 91.354C91.2716 91.6593 90.7309 91.7756 90.1488 91.7027C89.5664 91.6227 88.9848 91.3351 88.4041 90.8397L86.1336 88.9027L87.0085 88.3677L89.1525 90.1967C89.5099 90.5015 89.8525 90.6845 90.1805 90.7456C90.5082 90.7996 90.812 90.741 91.092 90.5698C91.4047 90.3786 91.5502 90.1406 91.5287 89.8557C91.5069 89.5637 91.3025 89.2526 90.9154 88.9224L88.9054 87.2077L89.7803 86.6726Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M90.9259 85.972L91.8008 85.437L95.7762 88.8283L94.9012 89.3634L90.9259 85.972Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M94.3628 84.7207C94.1978 84.211 94.4327 83.762 95.0673 83.3739L95.9532 84.1297C95.8637 84.1626 95.7886 84.1977 95.7279 84.2348C95.3873 84.4431 95.228 84.6968 95.2501 84.9958C95.2672 85.2906 95.4743 85.6074 95.8713 85.9461L97.7995 87.591L96.9245 88.126L92.9491 84.7347L93.7821 84.2253L94.3628 84.7207Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M101.344 82.545C101.408 82.6 101.495 82.6812 101.605 82.7885L98.6724 84.582C99.0166 84.8004 99.3828 84.9181 99.7712 84.935C100.159 84.9448 100.514 84.8513 100.836 84.6544C101.247 84.4033 101.441 84.0736 101.419 83.6654L102.462 83.8676C102.507 84.1524 102.456 84.4198 102.309 84.6699C102.161 84.92 101.926 85.1435 101.604 85.3404C101.194 85.5915 100.745 85.7386 100.259 85.7816C99.7726 85.8246 99.2892 85.7677 98.8088 85.6108C98.3282 85.4468 97.8918 85.1975 97.4997 84.863C97.1126 84.5328 96.8469 84.1865 96.7025 83.8241C96.5578 83.4547 96.5456 83.1059 96.6659 82.778C96.7861 82.45 97.0306 82.1733 97.3992 81.9479C97.7632 81.7253 98.1744 81.601 98.6327 81.5752C99.0908 81.5422 99.5555 81.6105 100.027 81.7803C100.498 81.9429 100.937 82.1978 101.344 82.545ZM98.1437 82.5829C97.8637 82.7542 97.7151 82.9759 97.6977 83.2482C97.6801 83.5134 97.7967 83.7837 98.0476 84.0592L100.168 82.7622C99.8432 82.5395 99.4956 82.4104 99.1256 82.375C98.7556 82.3395 98.4283 82.4089 98.1437 82.5829Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M90.2019 85.3487C90.4134 85.372 90.5985 85.3351 90.7572 85.2381C90.9205 85.1382 90.9989 85.0103 90.9924 84.8544C90.9856 84.6914 90.9003 84.54 90.7365 84.4003C90.5826 84.2691 90.4024 84.1939 90.1958 84.1749C89.9843 84.1516 89.7992 84.1885 89.6405 84.2855C89.4819 84.3825 89.4035 84.5104 89.4053 84.6692C89.4022 84.8237 89.4801 84.9688 89.6389 85.1042C89.7977 85.2397 89.9854 85.3212 90.2019 85.3487Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M76.257 99.4411C76.3406 98.6509 76.5523 98.1123 76.8922 97.8254C77.2276 97.5295 77.6913 97.5098 78.2833 97.7664L80.7214 98.8229L80.5961 100.008L80.0898 99.7888C80.2553 100.022 80.3721 100.303 80.4403 100.634C80.5041 100.956 80.5147 101.318 80.4722 101.719C80.4297 102.121 80.3406 102.45 80.2049 102.707C80.064 102.961 79.8912 103.131 79.6866 103.217C79.4774 103.295 79.2534 103.282 79.0145 103.178C78.6406 103.016 78.3608 102.709 78.175 102.258C77.9848 101.797 77.9304 101.182 78.0118 100.412L78.1583 99.0254L78.096 98.9984C77.8052 98.8724 77.5699 98.8895 77.39 99.0496C77.2109 99.2031 77.0979 99.5009 77.0512 99.9429C77.0193 100.244 77.0242 100.558 77.0657 100.884C77.108 101.204 77.1834 101.492 77.292 101.749L76.5311 101.934C76.3947 101.581 76.3032 101.188 76.2567 100.754C76.2102 100.321 76.2103 99.8831 76.257 99.4411ZM79.791 101.151C79.8243 100.836 79.7991 100.535 79.7155 100.246C79.6275 99.9492 79.4849 99.7053 79.2878 99.5148L78.8049 99.3056L78.6679 100.601C78.5914 101.325 78.7375 101.766 79.1062 101.926C79.2828 102.002 79.4326 101.973 79.5555 101.837C79.6785 101.701 79.757 101.473 79.791 101.151Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M78.2236 75.9798C78.3528 75.8778 78.4337 75.7134 78.4662 75.4868C78.4997 75.2535 78.4708 75.0367 78.3795 74.8363C78.2841 74.6268 78.1521 74.4818 77.9836 74.4012C77.8253 74.3255 77.6841 74.3399 77.56 74.4444C77.4308 74.5464 77.3499 74.7108 77.3174 74.9374C77.2849 75.164 77.3138 75.3809 77.4041 75.5879C77.4893 75.7925 77.6137 75.9339 77.777 76.012C77.9404 76.0901 78.0893 76.0794 78.2236 75.9798Z",
          stroke: "#FAF8FF",
          "stroke-width": "0.2"
        }
      }),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0" } }, [
          _c("rect", {
            attrs: {
              width: "246",
              height: "149",
              fill: "white",
              transform: "translate(0 17)"
            }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
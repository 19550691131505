var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("div", { staticClass: "checkout" }, [
        _c("div", { staticClass: "checkout__content" }, [
          _c(
            "div",
            { staticClass: "content__cart" },
            [
              _vm.isCartEmpty
                ? _c("EmptyCart")
                : _c("CartWithProducts", {
                    attrs: { "display-settings": true }
                  })
            ],
            1
          ),
          _c("hr", { staticClass: "content__separator" }),
          _c(
            "div",
            { staticClass: "content__user-info" },
            [
              _c("LayoutLoader", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isSaving,
                    expression: "isSaving"
                  }
                ],
                staticClass: "user-info__loader"
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isSaving,
                      expression: "!isSaving"
                    }
                  ],
                  staticClass: "user-info__display"
                },
                [
                  _vm.displayUserCheckoutInfo
                    ? _c(
                        "div",
                        { staticClass: "display__summary" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "summary__title",
                              class: {
                                "summary__title--connected": _vm.hasContact
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.summaryTitle) + " ")]
                          ),
                          _vm.hasContact
                            ? _c("div", { staticClass: "summary__subtitle" }, [
                                _vm._v(" " + _vm._s(_vm.summarySubtitle) + " ")
                              ])
                            : _vm._e(),
                          !_vm.isMobile || _vm.getGiftItems.length > 0
                            ? _c(
                                "div",
                                { staticClass: "display__products" },
                                [
                                  _vm.isCartEmpty
                                    ? _c("EmptyCart")
                                    : _c("CartWithProducts", {
                                        attrs: { "display-settings": true }
                                      })
                                ],
                                1
                              )
                            : _vm._e(),
                          !!_vm.updatedOtpCredential
                            ? _c(
                                "div",
                                { staticClass: "summary__updated-credentials" },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "updated-credentials__icon",
                                    attrs: { icon: "lock" }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.credentialsUpdateMessage) +
                                      " "
                                  ),
                                  _c("font-awesome-icon", {
                                    staticClass: "updated-credentials__icon",
                                    attrs: { icon: "times" },
                                    on: {
                                      click: _vm.clearUpdatedOtpCredentials
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.displayConfirmPhone
                            ? _c(
                                "div",
                                {
                                  staticClass: "summary__confirm-phonenumber",
                                  on: { click: _vm.onCloseConfirmPhoneNumber }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "confirm-phonenumber__lock",
                                    attrs: { icon: "lock" }
                                  }),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "confirm-phonenumber__text"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "checkout.confirmedPhoneNumber"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("font-awesome-icon", {
                                    staticClass: "confirm-phonenumber__close",
                                    attrs: { icon: "times" }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.hasContact
                            ? _c("UserEmail", {
                                staticClass: "summary__email",
                                attrs: { disabled: _vm.isVerifyingOtpAccount },
                                on: {
                                  "email-changed": function($event) {
                                    return _vm.handleAuthInfoChange(
                                      "email",
                                      $event
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          !_vm.hasContact
                            ? _c("UserPhone", {
                                staticClass: "summary__phone",
                                attrs: {
                                  disabled: _vm.isVerifyingOtpAccount,
                                  "default-country": _vm.defaultCountry
                                },
                                on: {
                                  "phone-changed": function($event) {
                                    return _vm.handleAuthInfoChange(
                                      "phoneNumber",
                                      $event
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.errorOtpAccount
                            ? _c("AppError", {
                                attrs: { error: _vm.errorOtpAccount }
                              })
                            : _vm._e(),
                          _vm.authInfoCompleted || _vm.hasContact
                            ? _c("UserAddresses", {
                                ref: "address",
                                staticClass: "summary__addresses",
                                attrs: {
                                  "inside-modal": _vm.isInsideModal,
                                  "is-mobile": _vm.isMobile
                                },
                                on: {
                                  "edit-mode-state": function($event) {
                                    return _vm.handleEditModeState(
                                      "address",
                                      $event
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.shippingRequired &&
                          (_vm.authInfoCompleted || _vm.hasContact)
                            ? _c("UserShipping", {
                                staticClass: "summary__shipping",
                                attrs: {
                                  "inside-modal": _vm.isInsideModal,
                                  "is-mobile": _vm.isMobile
                                }
                              })
                            : _vm._e(),
                          _vm.displayPaymentMethods || _vm.hasContact
                            ? _c("UserPaymentMethods", {
                                ref: "creditCard",
                                staticClass: "summary__payment",
                                attrs: {
                                  "inside-modal": _vm.isInsideModal,
                                  "is-mobile": _vm.isMobile,
                                  "display-title": false
                                },
                                on: {
                                  "edit-mode-state": function($event) {
                                    return _vm.handleEditModeState(
                                      "creditCard",
                                      $event
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.isMobile && _vm.hasSubscriptionItems
                            ? _c("SubscriptionSettings")
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("CartTotal"),
                  !_vm.hasContact
                    ? _c("UserOptions", { staticClass: "display__options" })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "checkout__footer" },
          [
            _c("LayoutBrand", { staticClass: "footer__brand" }),
            _c("CheckoutLinkMainFooter", {
              staticClass: "footer__action",
              attrs: { "action-disabled": _vm.mainActionDisabled },
              on: { pay: _vm.handlePay }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
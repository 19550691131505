var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quantity" },
    [
      _c("AppIconButton", {
        staticClass: "quantity__minus",
        attrs: {
          disabled: _vm.isDecreaseDisabled,
          name: "minus",
          color: "dark",
          size: "small"
        },
        nativeOn: {
          click: function($event) {
            return _vm.onQuantityDecrement.apply(null, arguments)
          }
        }
      }),
      _c("div", { staticClass: "quantity__number" }, [
        _vm._v(" " + _vm._s(_vm.quantity) + " ")
      ]),
      _c("AppIconButton", {
        staticClass: "quantity__plus",
        attrs: {
          disabled: _vm.isIncreaseDisabled,
          name: "plus",
          color: "dark",
          size: "small"
        },
        nativeOn: {
          click: function($event) {
            return _vm.onQuantityIncrement.apply(null, arguments)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ProductDrawer", {
    attrs: {
      value: _vm.drawerOpen,
      tab: _vm.drawerTab,
      product: _vm.product,
      images: _vm.mappedImages,
      "selected-variant": _vm.selectedVariant,
      preview: false,
      description: _vm.formattedDescription,
      "min-quantity-product": _vm.minQuantityProduct,
      "custom-fields-values": _vm.customFieldsValues,
      "user-context": _vm.userContext,
      "purchase-option": _vm.purchaseOption,
      "product-price": _vm.productPrice,
      "discounted-price": _vm.discountedPrice,
      currency: _vm.getCurrency,
      "is-quantity-max-reached": _vm.isQuantityMaxReached,
      "add-to-cart-disabled": _vm.addToCartDisabled,
      "add-to-cart-btn-text": _vm.addToCartButtonText,
      "has-options": _vm.hasOptions
    },
    on: {
      "update:tab": function($event) {
        _vm.drawerTab = $event
      },
      input: _vm.closeProductDetails,
      "add-to-cart": _vm.handleAddToCart,
      "changed-variant": _vm.onVariantChange
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-phone-code" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.code,
          expression: "code"
        }
      ],
      staticClass: "form-phone-code__field",
      class: { "form-phone-code__field--error": _vm.hasAnyError },
      attrs: {
        type: "tel",
        "data-cy": "form-phone-code-input",
        inputmode: "number",
        autocomplete: "one-time-code",
        placeholder: _vm.placeholder,
        pattern: _vm.pattern,
        maxlength: _vm.defaultPhoneCodeLength
      },
      domProps: { value: _vm.code },
      on: {
        input: [
          function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.code = $event.target.value
          },
          _vm.onInput
        ]
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout-overlay" }, [
    _c(
      "div",
      {
        staticClass: "layout-overlay__container",
        on: { click: _vm.checkIfCanClose }
      },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
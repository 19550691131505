var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-options" },
    [
      _c("FormCheckbox", {
        attrs: {
          "data-test-id": "sign-up",
          label: _vm.$t("registration.actions.signUp"),
          value: _vm.signUp
        },
        on: {
          "value-changed": function($event) {
            return _vm.onSignUpOptionChange($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
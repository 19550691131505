var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-address-input" }, [
    !_vm.showCompleteForm
      ? _c(
          "label",
          {
            staticClass: "form-address-input__label",
            attrs: { "data-test-id": "form-address-input-label" }
          },
          [
            _c("gmap-autocomplete", {
              staticClass: "form-address-input__field",
              class: { "form-address-input__field--error": _vm.displayError },
              attrs: {
                "data-test-id": "form-address-input-field",
                type: "text",
                role: "presentation",
                name: "search",
                placeholder: " ",
                value: _vm.value,
                "select-first-on-enter": true,
                required: _vm.required
              },
              on: { place_changed: _vm.onChange },
              nativeOn: {
                focus: function($event) {
                  return _vm.onFocus.apply(null, arguments)
                }
              }
            }),
            _c("span", { staticClass: "field__label" }, [
              _vm._v(_vm._s(_vm.label))
            ])
          ],
          1
        )
      : _vm._e(),
    _vm.showCompleteForm
      ? _c(
          "div",
          { staticClass: "complete-form" },
          [
            _c("div", { staticClass: "complete-form__message" }, [
              _vm._v(" " + _vm._s(_vm.$t("address.form.message")) + " ")
            ]),
            _c("FormInput", {
              staticClass: "complete-form__field",
              attrs: {
                value: _vm.address.address_components.line1,
                label: _vm.$t("address.form.address.label"),
                placeholder: _vm.$t("address.form.address.placeholder")
              },
              on: {
                "value-changed": function($event) {
                  return _vm.onCompleteAddressChange("line1", $event)
                }
              }
            }),
            _c(
              "div",
              {
                staticClass:
                  "complete-form__display complete-form__display--first"
              },
              [
                _c("FormInput", {
                  staticClass: "display__field",
                  attrs: {
                    value: _vm.address.address_components.city,
                    label: _vm.$t("address.form.city.label"),
                    placeholder: _vm.$t("address.form.city.placeholder")
                  },
                  on: {
                    "value-changed": function($event) {
                      return _vm.onCompleteAddressChange("city", $event)
                    }
                  }
                }),
                _c("FormInput", {
                  staticClass: "display__field",
                  attrs: {
                    value: _vm.address.address_components.zip,
                    label: _vm.$t("address.form.zip.label"),
                    placeholder: _vm.$t("address.form.zip.placeholder")
                  },
                  on: {
                    "value-changed": function($event) {
                      return _vm.onCompleteAddressChange("zip", $event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "complete-form__display" },
              [
                _c("SimpleDropdown", {
                  staticClass: "display__field",
                  attrs: {
                    label: _vm.$t("address.form.country.label"),
                    value: _vm.address.address_components.country,
                    options: _vm.countries
                  },
                  on: {
                    "value-changed": function($event) {
                      return _vm.onCompleteAddressChange("country", $event)
                    }
                  }
                }),
                _vm.provinces.length
                  ? _c("SimpleDropdown", {
                      staticClass: "display__field",
                      attrs: {
                        label: _vm.$t("address.form.province.label"),
                        "auto-select-first": true,
                        value: _vm.address.address_components.province,
                        options: _vm.provinces
                      },
                      on: {
                        "value-changed": function($event) {
                          return _vm.onCompleteAddressChange("province", $event)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.displayError
      ? _c(
          "span",
          {
            staticClass: "form-address-input__error-message",
            attrs: { "data-test-id": "form-address-input-error" }
          },
          [_vm._v(" " + _vm._s(_vm.error) + " ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
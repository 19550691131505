var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cart-total" },
    [
      _vm.hasError ? _c("AppError", { attrs: { error: _vm.error } }) : _vm._e(),
      _c(
        "div",
        { staticClass: "cart-total__content" },
        [
          _c("div", { staticClass: "content__detail" }, [
            _c("div", { staticClass: "detail__label" }, [
              _vm._v(_vm._s(_vm.$t("checkout.price")))
            ]),
            _c(
              "div",
              { staticClass: "detail__price" },
              [
                _c("CheckoutPrice", {
                  attrs: { amount: _vm.getTotalET, currency: _vm.getCurrency }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "content__detail" }, [
            _c("div", { staticClass: "detail__label" }, [
              _vm._v(_vm._s(_vm.$t("checkout.taxes")))
            ]),
            _c(
              "div",
              { staticClass: "detail__price" },
              [
                _vm.getSelectedAddress
                  ? _c("CheckoutPrice", {
                      attrs: {
                        amount: _vm.getTotalVAT,
                        currency: _vm.getCurrency
                      }
                    })
                  : _c("span", [
                      _vm._v(_vm._s(_vm.$t("checkoutLink.checkout.nextStep")))
                    ])
              ],
              1
            )
          ]),
          _c("div", { staticClass: "content__detail" }, [
            _c("div", { staticClass: "detail__label" }, [
              _vm._v(_vm._s(_vm.$t("checkout.shipping")))
            ]),
            _c(
              "div",
              { staticClass: "detail__price" },
              [
                _vm.getSelectedAddress
                  ? _c("CheckoutPrice", {
                      attrs: {
                        amount: _vm.getTotalShipping,
                        currency: _vm.getCurrency
                      }
                    })
                  : _c("span", [
                      _vm._v(_vm._s(_vm.$t("checkoutLink.checkout.nextStep")))
                    ])
              ],
              1
            )
          ]),
          _c("CheckoutDiscount", { staticClass: "content__discount" }),
          _vm.couponAllowed
            ? _c(
                "div",
                {
                  staticClass: "content__discount-field",
                  attrs: { "data-test-id": "coupon-wrapper" }
                },
                [
                  _c("CheckoutTotalCouponModal", {
                    staticClass: "details__coupon",
                    attrs: {
                      "error-message": _vm.couponError.key || "",
                      "has-error": !!_vm.couponError.key,
                      "is-loading": _vm.couponAdding || _vm.couponRemoving,
                      "hide-border-bottom": true
                    },
                    on: { "submit-triggered": _vm.onCouponAdded }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "content__total" },
            [
              _c("div", { staticClass: "total__label" }, [
                _vm._v(_vm._s(_vm.$t("checkout.total")))
              ]),
              _c("CheckoutDiscountablePrice", {
                staticClass: "total__price",
                attrs: {
                  amount: _vm.getTotalCart,
                  currency: _vm.getCurrency,
                  "discounted-amount": _vm.getTotalDiscountedCart,
                  variant: "flat"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
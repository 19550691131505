var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "checkout-total-coupon-modal coupon",
      class: { "no-border": _vm.hideBorderBottom }
    },
    [
      _vm.isLoading
        ? _c("LayoutLoader", {
            staticClass: "coupon__loading",
            attrs: { height: 60, width: 60 }
          })
        : _c("FormCouponField", {
            attrs: {
              "error-message": _vm.errorMessage,
              "has-error": _vm.hasError
            },
            on: { submit: _vm.onSubmitTriggered }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "layout-loader",
      class: { "layout-loader--text": _vm.isText }
    },
    [
      _c(
        "svg",
        {
          staticStyle: {
            margin: "auto",
            background: "transparent",
            display: "block"
          },
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: _vm.width,
            height: _vm.height,
            viewBox: "0 0 100 100",
            preserveAspectRatio: "xMidYMid"
          }
        },
        [
          _c("g", { attrs: { transform: "translate(20 50)" } }, [
            _c(
              "circle",
              {
                attrs: {
                  cx: "0",
                  cy: "0",
                  r: "6",
                  fill: _vm.color,
                  transform: "scale(0.834619 0.834619)"
                }
              },
              [
                _c("animateTransform", {
                  attrs: {
                    attributeName: "transform",
                    type: "scale",
                    begin: "-0.375s",
                    calcMode: "spline",
                    keySplines: "0.3 0 0.7 1;0.3 0 0.7 1",
                    values: "0;1;0",
                    keyTimes: "0;0.5;1",
                    dur: "1s",
                    repeatCount: "indefinite"
                  }
                })
              ],
              1
            )
          ]),
          _c("g", { attrs: { transform: "translate(40 50)" } }, [
            _c(
              "circle",
              {
                attrs: {
                  cx: "0",
                  cy: "0",
                  r: "6",
                  fill: _vm.color,
                  transform: "scale(0.999994 0.999994)"
                }
              },
              [
                _c("animateTransform", {
                  attrs: {
                    attributeName: "transform",
                    type: "scale",
                    begin: "-0.25s",
                    calcMode: "spline",
                    keySplines: "0.3 0 0.7 1;0.3 0 0.7 1",
                    values: "0;1;0",
                    keyTimes: "0;0.5;1",
                    dur: "1s",
                    repeatCount: "indefinite"
                  }
                })
              ],
              1
            )
          ]),
          _c("g", { attrs: { transform: "translate(60 50)" } }, [
            _c(
              "circle",
              {
                attrs: {
                  cx: "0",
                  cy: "0",
                  r: "6",
                  fill: _vm.color,
                  transform: "scale(0.831818 0.831818)"
                }
              },
              [
                _c("animateTransform", {
                  attrs: {
                    attributeName: "transform",
                    type: "scale",
                    begin: "-0.125s",
                    calcMode: "spline",
                    keySplines: "0.3 0 0.7 1;0.3 0 0.7 1",
                    values: "0;1;0",
                    keyTimes: "0;0.5;1",
                    dur: "1s",
                    repeatCount: "indefinite"
                  }
                })
              ],
              1
            )
          ]),
          _c("g", { attrs: { transform: "translate(80 50)" } }, [
            _c(
              "circle",
              {
                attrs: {
                  cx: "0",
                  cy: "0",
                  r: "6",
                  fill: _vm.color,
                  transform: "scale(0.498237 0.498237)"
                }
              },
              [
                _c("animateTransform", {
                  attrs: {
                    attributeName: "transform",
                    type: "scale",
                    begin: "0s",
                    calcMode: "spline",
                    keySplines: "0.3 0 0.7 1;0.3 0 0.7 1",
                    values: "0;1;0",
                    keyTimes: "0;0.5;1",
                    dur: "1s",
                    repeatCount: "indefinite"
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
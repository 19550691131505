var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "not-opened" },
    [
      _vm.getCheckoutLinkStartDate
        ? _c(
            "div",
            { staticClass: "not-opened__countdown" },
            [
              _c("p", { staticClass: "countdown__title" }, [
                _vm._v(_vm._s(_vm.$t("preRegistration.countdown")))
              ]),
              _c("CountDown", {
                attrs: { date: _vm.getCheckoutLinkStartDate },
                on: { done: _vm.reloadPage }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.preRegistrationContent
        ? _c(
            "div",
            { staticClass: "not-opened__page" },
            [
              _vm.preRegistrationContent
                ? _c("ContentBlockReadOnly", {
                    attrs: { content: _vm.preRegistrationContent }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      !_vm.contactId ? _c("BottomBarNotOpenedYet") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./ContentBlockReadOnly.vue?vue&type=template&id=37a508e0&scoped=true&"
import script from "./ContentBlockReadOnly.vue?vue&type=script&lang=js&"
export * from "./ContentBlockReadOnly.vue?vue&type=script&lang=js&"
import style0 from "./ContentBlockReadOnly.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ContentBlockReadOnly.vue?vue&type=style&index=1&id=37a508e0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a508e0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('37a508e0')) {
      api.createRecord('37a508e0', component.options)
    } else {
      api.reload('37a508e0', component.options)
    }
    module.hot.accept("./ContentBlockReadOnly.vue?vue&type=template&id=37a508e0&scoped=true&", function () {
      api.rerender('37a508e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/checkout-link/components/content/content-block/read-only/ContentBlockReadOnly.vue"
export default component.exports
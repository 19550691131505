import { render, staticRenderFns } from "./UserAgreement.vue?vue&type=template&id=323c7bfa&scoped=true&"
import script from "./UserAgreement.vue?vue&type=script&lang=js&"
export * from "./UserAgreement.vue?vue&type=script&lang=js&"
import style0 from "./UserAgreement.vue?vue&type=style&index=0&id=323c7bfa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323c7bfa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('323c7bfa')) {
      api.createRecord('323c7bfa', component.options)
    } else {
      api.reload('323c7bfa', component.options)
    }
    module.hot.accept("./UserAgreement.vue?vue&type=template&id=323c7bfa&scoped=true&", function () {
      api.rerender('323c7bfa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/checkout-link/components/user-agreement/UserAgreement.vue"
export default component.exports
import { render, staticRenderFns } from "./PreSellDisplay.vue?vue&type=template&id=0aff3134&scoped=true&"
import script from "./PreSellDisplay.vue?vue&type=script&lang=js&"
export * from "./PreSellDisplay.vue?vue&type=script&lang=js&"
import style0 from "./PreSellDisplay.vue?vue&type=style&index=0&id=0aff3134&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aff3134",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0aff3134')) {
      api.createRecord('0aff3134', component.options)
    } else {
      api.reload('0aff3134', component.options)
    }
    module.hot.accept("./PreSellDisplay.vue?vue&type=template&id=0aff3134&scoped=true&", function () {
      api.rerender('0aff3134', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/checkout-link/components/funnel/pre-sell-display/PreSellDisplay.vue"
export default component.exports
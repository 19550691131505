var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product product-in-offer" }, [
    _vm.product.imageUrl
      ? _c(
          "div",
          { staticClass: "product__image" },
          [
            _c("ProductOptimizedImage", {
              attrs: {
                "data-test-id": "product-optimized-image",
                alt: _vm.product.label,
                src: _vm.product.imageUrl,
                width: _vm.imageWidth
              }
            })
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "product__description" }, [
      _c(
        "span",
        {
          staticClass: "description__title",
          attrs: { "data-test-id": "description-title" }
        },
        [_vm._v(" " + _vm._s(_vm.productTitle) + " ")]
      ),
      _c(
        "span",
        {
          staticClass: "description__subtitle",
          attrs: { "data-test-id": "description-subtitle" }
        },
        [_vm._v(" " + _vm._s(_vm.optionsAndCustomFields) + " ")]
      )
    ]),
    _c(
      "div",
      { staticClass: "product__right-side" },
      [
        _c("CheckoutPrice", {
          staticClass: "rs__price",
          attrs: {
            "data-test-id": "checkout-product-price",
            amount: _vm.product.price,
            currency: _vm.product.currency
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "authentication-code" }, [
    _c("div", { staticClass: "authentication-code__container" }, [
      _c("div", { staticClass: "container__body" }, [
        _vm.isRegularAuthent
          ? _c("h1", { staticClass: "body__title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("checkoutLink.authentication.code.title")) +
                  " "
              ),
              _c("span", { staticClass: "title__icon" })
            ])
          : _vm._e(),
        _c("h2", { staticClass: "body__subtitle" }, [
          _vm._v(" " + _vm._s(_vm.subtitleText) + " ")
        ]),
        !_vm.isMismatchedInfo
          ? _c("div", [
              _c("p", { staticClass: "body__help" }, [
                _vm._v(" " + _vm._s(_vm.methodText) + " "),
                _c("span", { staticClass: "body__help--important" }, [
                  _vm._v(" " + _vm._s(_vm.sentTo) + " ")
                ]),
                _vm._v(". ")
              ]),
              !_vm.otpAccountSelected
                ? _c("p", { staticClass: "body__help" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "checkoutLink.authentication.code.help.text.afterMethod"
                          )
                        ) +
                        " "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "body__help--link",
                        on: { click: _vm.askNewCode }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "checkoutLink.authentication.code.help.link"
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c("span", [_vm._v(" | ")]),
                    _c(
                      "a",
                      {
                        staticClass: "body__help--link",
                        on: { click: _vm.restartAuth }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "checkoutLink.authentication.code.help.restart"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm.isMismatchedInfo
          ? _c(
              "p",
              { staticClass: "body__help" },
              [
                _c("i18n", {
                  attrs: { path: _vm.mismatchedTextPath },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "uniqueInfo",
                        fn: function() {
                          return [_vm._v(" " + _vm._s(_vm.uniqueInfo) + " ")]
                        },
                        proxy: true
                      },
                      {
                        key: "sentTo",
                        fn: function() {
                          return [
                            _c(
                              "span",
                              { staticClass: "body__help--important" },
                              [_vm._v(" " + _vm._s(_vm.sentTo) + " ")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    458655891
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.isMismatchedInfo
          ? _c("p", { staticClass: "body__help" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "checkoutLink.authentication.code.help.text.afterMethod"
                    )
                  ) +
                  " "
              ),
              _c(
                "a",
                {
                  staticClass: "body__help--link",
                  on: { click: _vm.askNewCode }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("checkoutLink.authentication.code.help.link")
                      ) +
                      " "
                  )
                ]
              ),
              _c("span", [_vm._v(" | ")]),
              _c(
                "a",
                {
                  staticClass: "body__help--link",
                  on: { click: _vm.restartAuth }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("checkoutLink.authentication.code.help.restart")
                      ) +
                      " "
                  )
                ]
              )
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "body__code" },
          [
            _vm.errorCode || _vm.errorOtpAccount
              ? _c("AppError", {
                  staticClass: "code__error",
                  attrs: {
                    "data-cy": "authentication-code-error",
                    error: _vm.errorCode || _vm.errorOtpAccount
                  }
                })
              : _vm._e(),
            !_vm.isVerifying && !_vm.isSaving
              ? _c("FormPhoneCode", {
                  attrs: {
                    "data-test-id": "authentication-code-body-phone",
                    "has-external-error": !!_vm.errorCode
                  },
                  on: { code: _vm.onVerifyCode }
                })
              : _c("LayoutLoader")
          ],
          1
        ),
        _vm.isRegularAuthent
          ? _c("p", { staticClass: "body__help" }, [
              _c(
                "a",
                {
                  staticClass: "body__help--link-secondary",
                  attrs: { "data-test-id": "change-otp-method" },
                  on: { click: _vm.changeMethod }
                },
                [_vm._v(" " + _vm._s(_vm.changeMethodText) + " ")]
              )
            ])
          : _vm._e(),
        _vm.otpAccountSelected
          ? _c("p", { staticClass: "body__help" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("checkoutLink.authentication.code.help.notReceived")
                  ) +
                  " "
              ),
              _c("br"),
              _c(
                "a",
                {
                  staticClass: "body__help--link",
                  attrs: { "data-test-id": "change-otp-method" },
                  on: { click: _vm.askNewCode }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "checkoutLink.authentication.code.help.linkAlternate"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c("span", [_vm._v(" | ")]),
              _c(
                "a",
                {
                  staticClass: "body__help--link",
                  on: { click: _vm.restartAuth }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("checkoutLink.authentication.code.help.restart")
                      ) +
                      " "
                  )
                ]
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-geo-pin",
      attrs: {
        width: "11",
        height: "15",
        viewBox: "0 0 11 15",
        fill: "currentColor",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M5.25 0.75C2.32422 0.75 0 3.10156 0 6C0 8.13281 0.710938 8.73438 4.70312 14.4766C4.94922 14.8594 5.52344 14.8594 5.76953 14.4766C9.76172 8.70703 10.5 8.13281 10.5 6C10.5 3.10156 8.14844 0.75 5.25 0.75ZM5.25 8.1875C4.01953 8.1875 3.0625 7.20312 3.0625 6C3.0625 4.76953 4.01953 3.8125 5.25 3.8125C6.45312 3.8125 7.4375 4.76953 7.4375 6C7.4375 7.20312 6.45312 8.1875 5.25 8.1875Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
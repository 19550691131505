var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkout-link-layout-wrapper" },
    [
      _c("InfoBar"),
      _c("div", { staticClass: "checkout-link-layout" }, [
        _vm.hasSlotOverlay()
          ? _c("div", { staticClass: "checkout-link-layout__block" }, [
              _c(
                "div",
                { staticClass: "block__card" },
                [
                  _vm._t("overlay"),
                  _c(
                    "div",
                    { staticClass: "block__brand" },
                    [_c("LayoutBrand")],
                    1
                  )
                ],
                2
              )
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "checkout-link-layout__content",
            class: {
              blur: _vm.isBlur,
              "checkout-link-layout__content-confirmation":
                _vm.isConfirmationRoute
            }
          },
          [_vm._t("content")],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
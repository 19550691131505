var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-error" }, [
    _c("span", { staticClass: "icon" }),
    _vm.error.message && _vm.error.key
      ? _c("span", [
          _vm._v(
            _vm._s(_vm.$t(_vm.error.message)) +
              " (" +
              _vm._s(_vm.getTranslatedKey) +
              ")"
          )
        ])
      : _vm.error.message
      ? _c("span", {
          domProps: { innerHTML: _vm._s(_vm.$t(_vm.error.message)) }
        })
      : _vm.error.key
      ? _c("span", { domProps: { innerHTML: _vm._s(_vm.getTranslatedKey) } })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
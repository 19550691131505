var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "footer",
      staticClass: "checkout-link-footer",
      class: { flat: !_vm.hasMobileBottomSheet },
      style: { height: _vm.footerHeight }
    },
    [
      _vm.hasSlotHeader()
        ? _c(
            "div",
            {
              ref: "header",
              staticClass: "checkout-link-footer__header",
              style: { height: _vm.headerHeight },
              attrs: { "data-test-id": "checkout-link-footer-header" }
            },
            [_vm._t("header")],
            2
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "checkout-link-footer__action",
          attrs: { "data-test-id": "checkout-link-footer-action" }
        },
        [
          _vm.hasSlotContent()
            ? _c(
                "div",
                {
                  ref: "content",
                  staticClass: "action__content",
                  style: { height: _vm.contentHeight }
                },
                [_vm._t("content")],
                2
              )
            : _vm._e(),
          _vm.hasSlotSide()
            ? _c(
                "div",
                { ref: "side", staticClass: "action__side" },
                [_vm._t("side")],
                2
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "action__button",
              class: { "full-width": _vm.isMobile && !_vm.hasSlotSide() },
              attrs: { "data-test-id": "checkout-link-footer-action-button" }
            },
            [
              _vm.hasSlotAction()
                ? _c(
                    "div",
                    { staticClass: "button__side" },
                    [_vm._t("action")],
                    2
                  )
                : _vm._e(),
              _c(
                "AppButton",
                {
                  staticClass: "button__main action__button--flat",
                  attrs: {
                    type: "primary",
                    disabled: _vm.actionDisabled,
                    "icon-prefix": _vm.iconPrefix,
                    "data-cy": "checkout-link-footer-action-button",
                    "data-test-id": "checkout-link-footer-action-button-element"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.emitClick.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.actionText) +
                      " " +
                      _vm._s(_vm.hasAmount ? _vm.actionAmount : "") +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _vm.paymentErrorMessage
            ? _c("AppError", {
                staticClass: "action__payment-error",
                attrs: {
                  "data-test-id":
                    "checkout-link-footer-action-payment-error-element",
                  error: _vm.paymentErrorMessage
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
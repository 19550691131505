var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "discount" },
    [
      _vm._l(_vm.mappedCoupons, function(coupon) {
        return _c(
          "div",
          { key: coupon.id, staticClass: "discount__item" },
          [
            _c("div", { staticClass: "item__title" }, [
              _c(
                "div",
                { staticClass: "button" },
                [
                  _vm._v(" " + _vm._s(coupon.name) + " "),
                  _c("AppIconButton", {
                    staticClass: "close",
                    attrs: { name: "times", size: "medium" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onCouponRemoved(coupon.id)
                      }
                    }
                  })
                ],
                1
              )
            ]),
            coupon.value
              ? _c("CheckoutPrice", {
                  staticClass: "item__value",
                  attrs: {
                    prefix: "-",
                    amount: coupon.value,
                    currency: _vm.getCurrency,
                    variant: "dark"
                  }
                })
              : _vm._e()
          ],
          1
        )
      }),
      _vm._l(_vm.visibleMappedFixedCoupons, function(coupon) {
        return _c(
          "div",
          { key: coupon.id, staticClass: "discount__item" },
          [
            _c("div", { staticClass: "item__title" }, [
              coupon.name === "DISCOUNT"
                ? _c("div", { staticClass: "button" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("checkoutLink.discount.discountTitle")) +
                        " "
                    )
                  ])
                : _c("div", { staticClass: "button" }, [
                    _vm._v(" " + _vm._s(coupon.name) + " ")
                  ])
            ]),
            _c("CheckoutPrice", {
              staticClass: "item__value",
              attrs: {
                prefix: "-",
                amount: coupon.value,
                currency: _vm.getCurrency,
                variant: "dark"
              }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
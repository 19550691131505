var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subscription-settings" }, [
    _c(
      "div",
      { staticClass: "subscription-settings__frequency" },
      [
        _c("SubscriptionFrequency", {
          attrs: {
            "frequency-options": _vm.getCheckoutLinkFrequencies,
            "selected-frequency-option": _vm.getSelectedFrequency,
            "is-loading": _vm.isFrequencyLoading
          },
          on: { "save-frequency-option": _vm.onSaveFrequency }
        })
      ],
      1
    ),
    _vm.subscriptionShippingRequired && !_vm.areShippingOptionsIdentical
      ? _c(
          "div",
          { staticClass: "subscription-settings__shipping" },
          [
            _c("h4", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.$t("cart.subscriptionShipping")) + " ")
            ]),
            _c("UserSubscriptionShipping", {
              attrs: { "inside-modal": _vm.isInsideModal }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
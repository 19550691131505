import { render, staticRenderFns } from "./IconTags.vue?vue&type=template&id=83d8c294&scoped=true&"
import script from "./IconTags.vue?vue&type=script&lang=js&"
export * from "./IconTags.vue?vue&type=script&lang=js&"
import style0 from "./IconTags.vue?vue&type=style&index=0&id=83d8c294&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83d8c294",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('83d8c294')) {
      api.createRecord('83d8c294', component.options)
    } else {
      api.reload('83d8c294', component.options)
    }
    module.hot.accept("./IconTags.vue?vue&type=template&id=83d8c294&scoped=true&", function () {
      api.rerender('83d8c294', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/checkout-link/components/icon/IconTags.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pre-sell-product",
      class: { "pre-sell-product--mono-product": _vm.isMonoProductFunnel }
    },
    [
      _c("AppGallery", {
        staticClass: "pre-sell-product__image",
        attrs: {
          images: [_vm.preferredVariant.imageUrl || _vm.product.imageUrl]
        }
      }),
      _c("div", { staticClass: "pre-sell-product__content" }, [
        _c(
          "div",
          {
            staticClass: "pre-sell-product__name",
            attrs: { title: _vm.product.name }
          },
          [_vm._v(" " + _vm._s(_vm.ellipsisTitle) + " ")]
        ),
        _c(
          "div",
          { staticClass: "pre-sell-product__price" },
          [
            _c("CheckoutPrice", {
              staticClass: "price__current",
              attrs: {
                amount: _vm.preferredVariant.price,
                currency: _vm.getFunnelCurrency
              }
            }),
            _vm.isDiscountedPrice
              ? _c("CheckoutPrice", {
                  staticClass: "price__former",
                  attrs: {
                    "data-test-id": "price-discounted",
                    amount: _vm.preferredVariant.originalPrice,
                    currency: _vm.getFunnelCurrency
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm.displayTaxesMessage
          ? _c("p", { staticClass: "pre-sell-product__taxes" }, [
              _vm._v(" " + _vm._s(_vm.$t("product.taxes")) + " ")
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "pre-sell-product__actions" },
          [
            _c(
              "AppButton",
              {
                staticClass: "actions__view",
                attrs: { type: "outlined", disabled: !_vm.active },
                on: { click: _vm.handleViewDetails }
              },
              [_vm._v(" " + _vm._s(_vm.$t("product.details")) + " ")]
            ),
            _c(
              "AppButton",
              {
                staticClass: "actions__add",
                attrs: { type: "primary", disabled: _vm.isAddToCartDisabled },
                on: { click: _vm.handleAddToCart }
              },
              [
                !_vm.isUpdatingOrSaving
                  ? _c("span", [_vm._v(_vm._s(_vm.addToCartButtonText))])
                  : _c("LayoutLoader", {
                      attrs: { height: 40, "is-text": true }
                    })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
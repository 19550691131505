var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-coupon-field" }, [
    _c(
      "span",
      {
        staticClass: "form-coupon-field__field",
        class: {
          "form-coupon-field__field--focus": _vm.isFocus,
          "form-coupon-field__field--error": _vm.hasError
        },
        attrs: { "data-test-id": "form-coupon-field" }
      },
      [
        _c("input", {
          ref: "coupon",
          staticClass: "field__input",
          attrs: {
            placeholder: _vm.$t("checkout.coupon.placeholder"),
            "data-test-id": "form-coupon-field-input"
          },
          domProps: { value: _vm.inputValue },
          on: {
            focus: _vm.setFocus,
            blur: _vm.removeFocus,
            change: _vm.onChange
          }
        }),
        _c(
          "AppButton",
          {
            staticClass: "field__submit-button",
            nativeOn: {
              click: function($event) {
                return _vm.onSubmit.apply(null, arguments)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("checkout.coupon.button")) + " ")]
        )
      ],
      1
    ),
    _vm.hasError
      ? _c(
          "span",
          {
            staticClass: "form-coupon-field__error-message",
            attrs: { "data-test-id": "form-coupon-field-error" }
          },
          [_vm._v(" " + _vm._s(_vm.$t(_vm.errorMessage)) + " ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
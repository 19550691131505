var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-select",
      class: [_vm.isMaterial ? "form-select--material" : "form-select--basic"]
    },
    [
      _vm.isMaterial
        ? _c("span", { staticClass: "field__label" }, [
            _vm._v(" " + _vm._s(_vm.label) + " ")
          ])
        : _vm._e(),
      _c("VSelect", {
        ref: "select",
        attrs: {
          options: _vm.options,
          reduce: function(obj) {
            return obj.value
          },
          label: "name",
          disabled: _vm.disabled,
          "append-to-body": "",
          "calculate-position": _vm.calculatePosition,
          loading: _vm.loading,
          clearable: false,
          searchable: false
        },
        on: { input: _vm.emitValueChange },
        scopedSlots: _vm._u(
          [
            {
              key: "spinner",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  loading
                    ? _c("span", { staticClass: "form-select__loading" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("checkout.summary.loading")) + " "
                        )
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "selected-option",
              fn: function(option) {
                return [
                  _c("span", { staticClass: "ellipsis" }, [
                    _c("span", { staticClass: "ellipsis__cut" }, [
                      _vm._v(" " + _vm._s(option.name) + " ")
                    ])
                  ])
                ]
              }
            },
            {
              key: "option",
              fn: function(option) {
                return [
                  _c("div", { staticClass: "form-select__options" }, [
                    _c("span", [_vm._v(_vm._s(option.name))])
                  ]),
                  _c("font-awesome-icon", {
                    staticClass: "form-select__check",
                    attrs: { icon: "check" }
                  })
                ]
              }
            },
            _vm.addOption
              ? {
                  key: "list-footer",
                  fn: function() {
                    return [
                      _c("li", [
                        _c(
                          "div",
                          { staticClass: "form-select__add" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "plus" }
                            }),
                            _c("span", { on: { click: _vm.emitAddOption } }, [
                              _vm._v(_vm._s(_vm.addOptionLabel))
                            ])
                          ],
                          1
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedValue,
          callback: function($$v) {
            _vm.selectedValue = $$v
          },
          expression: "selectedValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
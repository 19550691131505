var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detailed" }, [
    _c(
      "div",
      { staticClass: "detailed__content" },
      [
        _c(
          "div",
          { staticClass: "content__cart" },
          [
            _c("h1", { staticClass: "cart__title" }, [
              _vm._v(" " + _vm._s(_vm.$t("details.review")) + " ")
            ]),
            _vm.isCartEmpty
              ? _c("EmptyCart")
              : _c("CartWithProducts", { attrs: { "display-settings": false } })
          ],
          1
        ),
        _c("hr", { staticClass: "content__separator" }),
        _c("CartTotal", { staticClass: "content__total" })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "detailed__footer" },
      [
        _c("LayoutBrand", { staticClass: "footer__brand" }),
        _c("CheckoutLinkFooter", {
          staticClass: "footer__action",
          attrs: {
            "action-text": _vm.actionText,
            "action-disabled": _vm.isCartEmpty
          },
          on: { click: _vm.processCheckout }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
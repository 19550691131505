var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-shipping" },
    [
      _c("SimpleDropdown", {
        staticClass: "user-shipping__selection-mode",
        attrs: {
          "inside-modal": _vm.insideModal,
          "is-material": _vm.isMaterial,
          label: _vm.$t("checkout.summary.shipping"),
          value:
            (_vm.getSelectedShipping && _vm.getSelectedShipping.id) || null,
          options: _vm.availableShippingOptions,
          disabled: _vm.disabledShippingMenu,
          loading: _vm.isShippingLoading,
          "auto-select-first": true
        },
        on: { "value-changed": _vm.onShippingChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "post-sell-offer", class: _vm.postSellOfferClasses },
    [
      _vm.details.isVisible
        ? _c("FunnelProductDetails", {
            attrs: { product: _vm.details.product, tab: _vm.details.tab },
            on: { click: _vm.closeDetails, "add-to-cart": _vm.addToCart }
          })
        : _vm._e(),
      _c("div", { staticClass: "post-sell-offer__header" }, [
        _c(
          "div",
          {
            staticClass: "header-countdown",
            class: { disabled: _vm.isTimerExpired }
          },
          [
            _c("FunnelCounter", {
              attrs: {
                "initial-duration": _vm.timerRemaining,
                "internal-timer": true
              },
              on: { elapsed: _vm.timerExpired }
            })
          ],
          1
        ),
        _c("div", { staticClass: "header-message" }, [
          _c("p", { staticClass: "header-message__title" }, [
            _vm._v(_vm._s(_vm.getFunnelTitle))
          ]),
          _c("p", { staticClass: "header-message__description" }, [
            _vm._v(_vm._s(_vm.getFunnelSubtitle))
          ])
        ])
      ]),
      _c(
        "div",
        { class: _vm.productDisplayClasses },
        _vm._l(_vm.getFunnelProducts, function(product) {
          return _c(
            "div",
            { key: product.productId, staticClass: "products-individual" },
            [
              _c("FunnelProduct", {
                attrs: { active: !_vm.isTimerExpired, product: product },
                on: {
                  "add-to-cart": _vm.addToCart,
                  "view-details": _vm.viewDetails
                }
              })
            ],
            1
          )
        }),
        0
      ),
      [
        _vm.insideModal
          ? _c(
              "ModalDisplay",
              { on: { close: _vm.closeModal } },
              [_c("router-view")],
              1
            )
          : _vm._e(),
        _vm.displayBottomBar ? _c("BottomBar") : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
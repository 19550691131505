var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "info-bar",
      class: { "info-bar--loaded": _vm.isLoaded },
      style: _vm.cssStyles
    },
    [
      _c("div", { staticClass: "info-bar__display" }, [
        _vm.countDownVisible
          ? _c(
              "div",
              { staticClass: "display__countdown" },
              [
                _c("IconClock", { staticClass: "countdown__icon" }),
                _c("span", { staticClass: "countdown__text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("checkoutLink.information.countdown")) +
                      " "
                  )
                ]),
                _c("CountDown", {
                  staticClass: "countdown__counter",
                  attrs: { date: _vm.countDownDate, "is-on-cart": true },
                  on: { done: _vm.onCountDownEnd }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.discountBracketsVisible
          ? _c(
              "div",
              { staticClass: "display__brackets" },
              [
                _c("DiscountBrackets", {
                  attrs: {
                    "checkout-link-incentive": _vm.getCheckoutLinkIncentive,
                    "total-cart": _vm.getTotalCart,
                    currency: _vm.getCurrency
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.discountFlatVisible
          ? _c(
              "div",
              { staticClass: "display__flat" },
              [
                _c("DiscountFlat", {
                  attrs: {
                    "checkout-link-incentive": _vm.getCheckoutLinkIncentive,
                    currency: _vm.getCurrency
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.giftVisible
          ? _c(
              "div",
              { staticClass: "display__gift" },
              [
                _c("IconGift", { staticClass: "gift-icon" }),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("checkoutLink.information.giftInCart")) +
                    " "
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "info-bar__cart" }, [
        _c(
          "button",
          {
            attrs: {
              type: "button",
              "data-test-id": "info-bar-cart-details",
              "data-cy": "info-bar-cart"
            },
            on: { click: _vm.openDetails }
          },
          [
            _vm.firstname
              ? _c("span", { staticClass: "cart__name" }, [
                  _vm._v(" " + _vm._s(_vm.firstNameEllipsis) + " ")
                ])
              : _vm._e(),
            _c("IconCart", { staticClass: "cart__icon" }),
            _c(
              "span",
              {
                staticClass: "cart__items-count",
                attrs: { "data-cy": "info-bar-product-count" }
              },
              [_vm._v(" " + _vm._s(_vm.getItemTotalInCart) + " ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
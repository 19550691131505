var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "display", class: _vm.containerClass },
    _vm._l(_vm.getFunnelProducts, function(product) {
      return _c("FunnelProduct", {
        key: product.producId,
        staticClass: "display__product",
        attrs: { product: product, active: !_vm.isTimerExpired },
        on: { "view-details": _vm.viewDetails, "add-to-cart": _vm.addToCart }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-checkbox" }, [
    _c(
      "label",
      {
        staticClass: "form-checkbox__label",
        class: { "form-checkbox__label--error": _vm.error },
        attrs: { "data-test-id": "form-checkbox-label" }
      },
      [
        _c("input", {
          staticClass: "form-checkbox__hidden-field",
          attrs: {
            "data-test-id": "form-checkbox-hidden-field",
            type: "checkbox",
            required: _vm.required
          },
          domProps: { checked: _vm.currentValue },
          on: {
            click: _vm.toggleValue,
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.toggleValue.apply(null, arguments)
            }
          }
        }),
        _c(
          "span",
          { staticClass: "form-checkbox__field" },
          [_c("font-awesome-icon", { attrs: { icon: "check" } })],
          1
        ),
        _vm._v(" " + _vm._s(_vm.label) + " ")
      ]
    ),
    _vm.error
      ? _c(
          "span",
          {
            staticClass: "form-checkbox__error-message",
            attrs: { "data-test-id": "form-checkbox-error" }
          },
          [_vm._v(" " + _vm._s(_vm.error) + " ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-addresses" },
    [
      _vm.displayEditMode
        ? _c("div", { staticClass: "user-addresses__edit-mode" }, [
            _c(
              "div",
              { staticClass: "edit-mode__form" },
              [
                _c(
                  "div",
                  { staticClass: "form__identity" },
                  [
                    _c("FormInput", {
                      staticClass: "identity__item",
                      attrs: {
                        value: _vm.form.firstname,
                        label: _vm.$t("address.firstname.label"),
                        error: _vm.errors.firstname,
                        "show-errors": !!_vm.fieldTouched.firstname
                      },
                      on: {
                        "input-focus": function($event) {
                          return _vm.onInputFocus("firstname")
                        },
                        "value-changed": function($event) {
                          return _vm.onNameValueChange("firstname", $event)
                        }
                      }
                    }),
                    _c("FormInput", {
                      staticClass: "identity__item",
                      attrs: {
                        value: _vm.form.lastname,
                        label: _vm.$t("address.lastname.label"),
                        error: _vm.errors.lastname,
                        "show-errors": !!_vm.fieldTouched.lastname
                      },
                      on: {
                        "input-focus": function($event) {
                          return _vm.onInputFocus("lastname")
                        },
                        "value-changed": function($event) {
                          return _vm.onNameValueChange("lastname", $event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c("FormAddressInput", {
                  staticClass: "form__address",
                  attrs: {
                    value: _vm.form.address,
                    label: _vm.$t("address.field.label"),
                    error: _vm.errors.addressFormatted,
                    "show-errors": !!_vm.fieldTouched.addressFormatted
                  },
                  on: {
                    "value-changed": function($event) {
                      return _vm.onNewAddressValueChange("address", $event)
                    },
                    "reset-value": _vm.onNewAddressResetAddress
                  }
                }),
                !_vm.hasSecondLine
                  ? _c(
                      "AppLink",
                      {
                        staticClass: "form__toggle",
                        attrs: { "icon-placement": "left", icon: "plus" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.toggleSecondLine.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("address.actions.addSecondLine")) +
                            " "
                        )
                      ]
                    )
                  : [
                      _c("FormInput", {
                        staticClass: "form__address-two",
                        attrs: {
                          value: _vm.form.line2,
                          label: _vm.$t("address.secondLine.label"),
                          error: _vm.errors.line2,
                          "show-errors": !!_vm.fieldTouched.line2
                        },
                        on: {
                          "input-focus": function($event) {
                            return _vm.onInputFocus("line2")
                          },
                          "value-input": function($event) {
                            return _vm.onNewAddressValueChange("line2", $event)
                          }
                        }
                      }),
                      _c(
                        "AppLink",
                        {
                          staticClass: "form__toggle",
                          attrs: { "icon-placement": "left", icon: "minus" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.toggleSecondLine.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("address.actions.removeSecondLine")
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
              ],
              2
            ),
            _vm.cancelAllowed
              ? _c("div", { staticClass: "form__cancel" }, [
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: { click: _vm.toggleEditMode }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("checkoutLink.checkout.cancel")) +
                          " "
                      )
                    ]
                  )
                ])
              : _vm._e()
          ])
        : _c("SimpleDropdown", {
            staticClass: "user-addresses__selection-mode",
            attrs: {
              "inside-modal": _vm.insideModal,
              "is-material": _vm.isMaterial,
              label: _vm.$t("checkout.summary.address"),
              value: _vm.selectedAddress,
              options: _vm.mappedAddresses,
              disabled: _vm.disabledDropdown,
              "add-option": _vm.hasEmptyOption,
              "auto-select-first": !_vm.displayEditMode,
              "add-option-label": _vm.$t("checkout.summary.addAddress")
            },
            on: {
              "first-value-selected": _vm.onSelectedAddressChange,
              "value-changed": _vm.onSelectedAddressChange,
              "add-option": _vm.toggleEditMode
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FormInput", {
        staticClass: "user-email",
        attrs: {
          type: "email",
          disabled: _vm.disabled,
          value: _vm.email,
          label: _vm.$t("registration.email.label"),
          placeholder: _vm.$t("registration.email.placeholder"),
          error: _vm.errors.email,
          "data-test-id": "email-input"
        },
        on: {
          "value-input": function($event) {
            return _vm.onNewEmailValueChange($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
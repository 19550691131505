var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-agreement" }, [
    _c(
      "div",
      { staticClass: "user-agreement__label" },
      [
        _c("i18n", {
          attrs: { path: "registration.agreement" },
          scopedSlots: _vm._u([
            {
              key: "privacyPolicy",
              fn: function() {
                return [
                  _vm.advancedSettings.privacyPolicyUrl
                    ? _c(
                        "a",
                        {
                          staticClass: "label__link",
                          attrs: {
                            href: _vm.advancedSettings.privacyPolicyUrl,
                            target: "_blank",
                            rel: "noopener noreferrer"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("registration.privacyPolicy")) +
                              " "
                          )
                        ]
                      )
                    : _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("registration.privacyPolicy")) +
                            " "
                        )
                      ])
                ]
              },
              proxy: true
            },
            {
              key: "terms",
              fn: function() {
                return [
                  _vm.advancedSettings.termsUrl
                    ? _c(
                        "a",
                        {
                          staticClass: "label__link",
                          attrs: {
                            href: _vm.advancedSettings.termsUrl,
                            target: "_blank",
                            rel: "noopener noreferrer"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("registration.terms")) + " "
                          )
                        ]
                      )
                    : _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("registration.terms")) + " ")
                      ])
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
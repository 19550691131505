var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-selection" }, [
    _c("div", { staticClass: "account-selection__container" }, [
      _c(
        "div",
        { staticClass: "container__body" },
        [
          _c("h2", { staticClass: "body__title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("checkoutLink.authentication.accountSelection.title")
                ) +
                " "
            )
          ]),
          _c("p", { staticClass: "body__help" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("checkoutLink.authentication.accountSelection.message")
                ) +
                " "
            )
          ]),
          _c(
            "div",
            { staticClass: "body__info-selection" },
            _vm._l(_vm.availableAccounts, function(account, idx) {
              return _c(
                "span",
                { key: "info-" + idx, staticClass: "info-selection__option" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedAccount,
                        expression: "selectedAccount"
                      }
                    ],
                    staticClass: "option__input",
                    attrs: { id: "info-" + idx, type: "radio" },
                    domProps: {
                      value: account.value,
                      checked: _vm._q(_vm.selectedAccount, account.value)
                    },
                    on: {
                      change: function($event) {
                        _vm.selectedAccount = account.value
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "option__label",
                      attrs: { for: "info-" + idx }
                    },
                    [_vm._v(_vm._s(account.display))]
                  )
                ]
              )
            }),
            0
          ),
          _c("AppButton", { on: { click: _vm.handleAccountUpdate } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "checkoutLink.authentication.accountSelection.confirmation"
                  )
                ) +
                " "
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "bottomBar",
      staticClass: "bottom-bar",
      class: {
        "bottom-bar--unregistered": !_vm.hasSomeInformation,
        "bottom-bar--loaded": _vm.isLoaded,
        "bottom-bar--with-display": _vm.isDisplayVisible
      },
      style: {
        "--bg-alpha-color": _vm.alphaColor,
        display: _vm.displayBottomBar
      }
    },
    [
      _c("div", { staticClass: "bottom-bar__blur" }),
      [
        _vm.hasSomeInformation && _vm.isLoaded
          ? [
              _c(
                "div",
                { staticClass: "bottom-bar__payment" },
                [
                  _vm.getSelectedCreditCard
                    ? _c("IconCreditCard", { staticClass: "payment__icon" })
                    : _vm._e(),
                  _c("UserPaymentMethods", {
                    staticClass: "payment__select",
                    attrs: {
                      "is-material": false,
                      "inside-modal": false,
                      "is-mobile": _vm.isMobile
                    }
                  })
                ],
                1
              ),
              _vm.existingShippingOptions && _vm.shippingRequired
                ? _c(
                    "div",
                    { staticClass: "bottom-bar__shipping" },
                    [
                      _c("IconTruckFull", { staticClass: "shipping__icon" }),
                      _c("UserShipping", {
                        staticClass: "shipping__select",
                        attrs: {
                          "is-material": false,
                          "inside-modal": false,
                          "is-mobile": _vm.isMobile
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "bottom-bar__address" },
                [
                  _vm.getSelectedAddress
                    ? _c("IconGeoPin", { staticClass: "address__icon" })
                    : _vm._e(),
                  _c("UserAddresses", {
                    staticClass: "address__select",
                    attrs: {
                      "is-material": false,
                      "inside-modal": false,
                      "is-mobile": _vm.isMobile
                    }
                  })
                ],
                1
              )
            ]
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "bottom-bar__thumbnails",
            attrs: { type: "button", disabled: _vm.isTimerExpired },
            on: { click: _vm.openDetails }
          },
          [
            _c("ProductsImages", {
              attrs: { "products-images": _vm.productsImages }
            })
          ],
          1
        )
      ],
      _c(
        "AppButton",
        {
          staticClass: "bottom-bar__action",
          attrs: {
            type: "primary",
            disabled: _vm.actionDisabled,
            "icon-prefix": _vm.buttonIcon,
            "data-test-id": "bottom-bar-action-button"
          },
          on: { click: _vm.openModal }
        },
        [_vm._v(" " + _vm._s(_vm.actionText) + " " + _vm._s(_vm.price) + " ")]
      ),
      _vm.isDisplayVisible
        ? _c(
            "div",
            { staticClass: "bottom-bar__bottom-line" },
            [
              _vm.discountFlatVisible
                ? _c("DiscountFlat", {
                    attrs: {
                      "checkout-link-incentive": _vm.getCheckoutLinkIncentive,
                      currency: _vm.getCurrency
                    }
                  })
                : _vm._e(),
              _vm.discountBracketsVisible
                ? _c("DiscountBrackets", {
                    attrs: {
                      "checkout-link-incentive": _vm.getCheckoutLinkIncentive,
                      "total-cart": _vm.getTotalCart,
                      currency: _vm.getCurrency
                    }
                  })
                : _vm._e(),
              _vm.giftVisible
                ? _c(
                    "div",
                    { staticClass: "bottom-line__gift" },
                    [
                      _c("IconGift", { staticClass: "gift-icon" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("checkoutLink.information.giftInCart")
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
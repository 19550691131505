var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "16",
        viewBox: "0 0 24 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("rect", {
        attrs: { width: "24", height: "16", rx: "2", fill: "#F2F0EC" }
      }),
      _c("g", { attrs: { opacity: "0.01" } }, [
        _c("rect", {
          attrs: {
            x: "2.32001",
            y: "1.6001",
            width: "19.35",
            height: "12.8",
            fill: "white"
          }
        })
      ]),
      _c("rect", {
        attrs: {
          x: "9.45001",
          y: "3.83008",
          width: "5.11",
          height: "8.34",
          fill: "#F26122"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M10 8.00028C9.99457 6.37772 10.7313 4.8417 12 3.83028C9.82643 2.12515 6.70764 2.3756 4.834 4.40573C2.96036 6.43586 2.96036 9.5647 4.834 11.5948C6.70764 13.625 9.82643 13.8754 12 12.1703C10.7289 11.1608 9.99159 9.62351 10 8.00028Z",
          fill: "#EA1D25"
        }
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d:
            "M20.41 11.0801V11.2901H20.3V11.2301L20.36 11.0801H20.41ZM20.2786 11.1765L20.3 11.2301V11.1301L20.2786 11.1765ZM20.24 11.0801L20.2786 11.1765L20.24 11.2601L20.18 11.1301V11.0801H20.24ZM20.07 11.1201V11.2901H20V11.1201H20.07Z",
          fill: "#F69E1E"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M20.58 8.00028C20.5748 10.0282 19.415 11.876 17.5911 12.7624C15.7672 13.6489 13.5977 13.4191 12 12.1703C13.2772 11.1634 14.0226 9.62668 14.0226 8.00028C14.0226 6.37389 13.2772 4.83719 12 3.83028C13.5957 2.57307 15.7696 2.33857 17.5968 3.22657C19.4239 4.11457 20.5826 5.96881 20.58 8.00028Z",
          fill: "#F69E1E"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
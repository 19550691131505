var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "product product-in-cart" }, [
        _c(
          "div",
          { staticClass: "product__image" },
          [
            _c("ProductOptimizedImage", {
              attrs: {
                "data-test-id": "product-optimized-image",
                alt: _vm.product.label,
                src: _vm.productImageBackup,
                width: _vm.imageWidth
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "product__details" }, [
          _c(
            "div",
            { staticClass: "details__top" },
            [
              _c("div", { staticClass: "product__description" }, [
                _c(
                  "span",
                  {
                    staticClass: "description__title",
                    attrs: {
                      "data-test-id": "description-title",
                      title: _vm.productName
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.productName) + " ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "description__subtitle",
                    attrs: { "data-test-id": "description-subtitle" }
                  },
                  [_vm._v(" " + _vm._s(_vm.optionsAndCustomFields) + " ")]
                )
              ]),
              _vm.canDelete
                ? _c("AppIconButton", {
                    staticClass: "products__trash",
                    attrs: {
                      "data-test-id": "products-trash",
                      name: "trash",
                      color: "muted",
                      size: "small",
                      disabled: !_vm.canDelete
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onDelete.apply(null, arguments)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "details__bottom" },
            [
              _vm.product.source !== "GIFT"
                ? _c("AppQuantity", {
                    staticClass: "product__quantity",
                    attrs: {
                      "data-test-id": "app-quantity",
                      "min-quantity": _vm.minQuantity || 1,
                      "max-quantity": _vm.maxQuantity,
                      "count-product-in-cart": _vm.getItemTotal(
                        _vm.product.productId
                      ),
                      "default-quantity": _vm.product.quantity
                    },
                    on: { "quantity-changed": _vm.onQuantityChanged }
                  })
                : _vm._e(),
              _vm.product.source === "GIFT" &&
              _vm.mappedProduct.variants.length > 1
                ? _c(
                    "AppButton",
                    {
                      staticClass: "btn-select-options",
                      attrs: { type: "primary" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.onSelectOptions.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("productInCart.selectOptions")) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "product__prices" },
                [
                  _vm.showDiscountedAmount
                    ? _c("CheckoutPrice", {
                        staticClass: "product__original-price",
                        attrs: {
                          amount: _vm.price,
                          currency: _vm.product.currency,
                          variant: "subdued"
                        }
                      })
                    : _vm._e(),
                  _c("CheckoutPrice", {
                    staticClass: "product__price",
                    attrs: {
                      "data-test-id": "checkout-product-price",
                      amount: _vm.discountedPrice,
                      currency: _vm.product.currency
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _vm.options.isVisible
        ? _c("ProductInCartOptions", {
            attrs: {
              product: _vm.mappedProduct,
              "variant-id": _vm.product.variantId,
              tab: _vm.options.tab
            },
            on: { close: _vm.closeOptions, "add-to-cart": _vm.onVariantUpdate }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-clock",
      attrs: {
        width: "12",
        height: "13",
        viewBox: "0 0 12 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M6 0.6875C2.78906 0.6875 0.1875 3.28906 0.1875 6.5C0.1875 9.71094 2.78906 12.3125 6 12.3125C9.21094 12.3125 11.8125 9.71094 11.8125 6.5C11.8125 3.28906 9.21094 0.6875 6 0.6875ZM8.15625 8.02344L7.6875 8.60938C7.61719 8.70312 7.5 8.77344 7.38281 8.77344C7.3125 8.77344 7.21875 8.72656 7.17188 8.67969L5.60156 7.50781C5.39062 7.36719 5.25 7.03906 5.25 6.78125V3.125C5.25 2.9375 5.41406 2.75 5.625 2.75H6.375C6.5625 2.75 6.75 2.9375 6.75 3.125V6.5L8.10938 7.50781C8.17969 7.57812 8.25 7.69531 8.25 7.78906C8.25 7.88281 8.20312 7.97656 8.15625 8.02344Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
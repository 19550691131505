var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "registration" }, [
    _c("div", { staticClass: "registration__content" }, [
      _c("div", { staticClass: "content__pre-registration" }, [
        _c("div", { staticClass: "pre-registration__title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.preRegistrationTitle ||
                  _vm.$t("preRegistration.registration.title")
              ) +
              " "
          )
        ]),
        _c("div", { staticClass: "pre-registration__text" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.preRegistrationSubTitle ||
                  _vm.$t("preRegistration.registration.text")
              ) +
              " "
          )
        ]),
        _c(
          "div",
          { staticClass: "pre-registration__countdown" },
          [
            _c("p", { staticClass: "countdown__title" }, [
              _vm._v(_vm._s(_vm.$t("preRegistration.countdown")))
            ]),
            _c("CountDown", { attrs: { date: _vm.getCheckoutLinkStartDate } })
          ],
          1
        )
      ]),
      _c("hr", { staticClass: "content__separator" }),
      _c("div", { staticClass: "content__user-info" }, [
        _c(
          "div",
          {
            staticClass: "user-info__form",
            attrs: { "data-test-id": "user-info__form" }
          },
          [
            _c("UserEmail", {
              attrs: { disabled: _vm.isVerifyingOtpAccount },
              on: {
                "email-changed": function($event) {
                  return _vm.handleAuthInfoChange("email", $event)
                }
              }
            }),
            _c("UserPhone", {
              attrs: {
                disabled: _vm.isVerifyingOtpAccount,
                "default-country": _vm.defaultCountry
              },
              on: {
                "phone-changed": function($event) {
                  return _vm.handleAuthInfoChange("phoneNumber", $event)
                }
              }
            }),
            _c("UserAddresses", {
              ref: "address",
              staticClass: "form__addresses",
              attrs: {
                "inside-modal": _vm.isInsideModal,
                "is-mobile": _vm.isMobile,
                "should-update-cart-on-change": false
              },
              on: {
                "edit-mode-state": function($event) {
                  return _vm.handleEditModeState("address", $event)
                }
              }
            }),
            !_vm.hidePaymentMethod
              ? _c("UserPaymentMethods", {
                  ref: "creditCard",
                  staticClass: "form__payment",
                  attrs: {
                    "inside-modal": _vm.isInsideModal,
                    "is-mobile": _vm.isMobile
                  },
                  on: {
                    "edit-mode-state": function($event) {
                      return _vm.handleEditModeState("creditCard", $event)
                    }
                  }
                })
              : _vm._e(),
            _c("UserOptions", { staticClass: "form__options" }),
            _c("UserAgreement", {
              staticClass: "form__agreement",
              attrs: { "advanced-settings": _vm.advancedSettings }
            }),
            _vm.hasError
              ? _c("AppError", { attrs: { error: _vm.error } })
              : _vm._e()
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "registration__footer" },
      [
        _c("LayoutBrand", { staticClass: "footer__brand" }),
        _c(
          "div",
          { staticClass: "footer__action" },
          [
            _c(
              "AppButton",
              {
                attrs: {
                  type: "primary",
                  disabled: _vm.mainActionDisabled,
                  "data-cy": "checkout-link-footer-action-button",
                  "data-test-id": "checkout-link-footer-action-button-element"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.submitPreRegister.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("preRegistration.registration.button")) +
                    " "
                )
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subscription-frequency" },
    [
      _c("h4", { staticClass: "subscription-frequency__title" }, [
        _vm._v(" " + _vm._s(_vm.$t("cart.frequency")) + " ")
      ]),
      _c("SimpleDropdown", {
        staticClass: "subscription-frequency__selection-mode",
        attrs: {
          "inside-modal": _vm.insideModal,
          "is-material": _vm.isMaterial,
          label: _vm.$t("checkout.summary.frequency"),
          value: _vm.selectedFrequencyOption || null,
          options: _vm.availableFrequencyOptions,
          disabled: _vm.disabledFrequencyMenu,
          loading: _vm.isLoading,
          "auto-select-first": true
        },
        on: { "value-changed": _vm.onFrequencyChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
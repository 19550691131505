import { render, staticRenderFns } from "./ProductsImages.vue?vue&type=template&id=b8113168&scoped=true&"
import script from "./ProductsImages.vue?vue&type=script&lang=js&"
export * from "./ProductsImages.vue?vue&type=script&lang=js&"
import style0 from "./ProductsImages.vue?vue&type=style&index=0&id=b8113168&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8113168",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b8113168')) {
      api.createRecord('b8113168', component.options)
    } else {
      api.reload('b8113168', component.options)
    }
    module.hot.accept("./ProductsImages.vue?vue&type=template&id=b8113168&scoped=true&", function () {
      api.rerender('b8113168', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/checkout-link/components/products-images/ProductsImages.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        fill: "none",
        viewBox: "0 0 48 71",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { "clip-path": "url(#b)" } }, [
        _c("path", {
          attrs: {
            d:
              "m5.0699 25.596l22.81 13.479c4.5276 2.6754 10.098 2.889 14.818 0.5681 1.2098-0.595 1.8596-1.7309 1.9299-2.9002v29.258c-0.0707 1.1689-0.7205 2.3043-1.9299 2.899-4.719 2.3209-10.29 2.1073-14.818-0.568l-22.81-13.479c-1.0904-0.6443-1.6515-1.7268-1.6994-2.8265v-29.255c-0.00458-0.1039-0.00457-0.208 0-0.3119v0.3119c0.04842 1.0992 0.60949 2.1809 1.6993 2.8249z",
            fill: "#C8C0D8"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "m5.0699 25.596l22.81 13.479c2.6273 1.5525 5.6058 2.276 8.5654 2.1564v29.256c-2.9596 0.1196-5.9381-0.6039-8.5653-2.1563l-22.81-13.479c-1.0904-0.6443-1.6515-1.7268-1.6994-2.8265v-29.255c-0.00458-0.1039-0.00457-0.208 0-0.3119v0.3119c0.04842 1.0992 0.60949 2.1809 1.6993 2.8249z",
            fill: "#E3DDED"
          }
        }),
        _c("g", { attrs: { filter: "url(#a)" } }, [
          _c("path", {
            attrs: {
              d:
                "m17.969 54.069l-1.7407-0.9574c-0.6949-0.3822-1.0451-1.1846-0.8528-1.954l0.3644-1.4575c0.0685-0.274-0.0035-0.5616-0.1684-0.791-0.1954-0.272-0.3736-0.572-0.528-0.8959-0.9183-1.9268-0.6488-3.972 0.6019-4.5681s3.0091 0.4826 3.9274 2.4093c0.7787 1.6338 0.7034 3.3527-0.1001 4.2011-0.2492 0.2632-0.4583 0.5953-0.4239 0.9562l0.2104 2.2093c0.0665 0.6982-0.6757 1.186-1.2902 0.848z",
              fill: "#6028FF"
            }
          })
        ]),
        _c("path", {
          attrs: {
            d:
              "m28.352 39.355l-22.81-13.479c-2.5756-1.5219-2.4309-5.2955 0.25364-6.6157 4.4115-2.1696 9.6194-1.97 13.852 0.531l22.81 13.479c2.5755 1.5219 2.4309 5.2955-0.2537 6.6158-4.4115 2.1696-9.6194 1.9699-13.852-0.5311z",
            fill: "#FAF8FF"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "m16.377 4.7704c-1.5186 0.65571-3.0752 2.4076-3.0752 6.6264v9.8448c0 1.2059-0.9775 2.1834-2.1833 2.1834-1.2058 0-2.1834-0.9775-2.1834-2.1834v-9.8448c0-5.3681 2.1106-9.0808 5.7108-10.635 3.4047-1.4701 7.4415-0.68231 10.724 1.2572 8.439 4.9866 13.695 12.937 13.695 24v9.5732c0 1.2058-0.9775 2.1833-2.1833 2.1833-0.7271 0-1.3712-0.3554-1.768-0.902-0.2613-0.3599-0.4153-0.8026-0.4153-1.2813v-9.5732c0-9.4083-4.3429-15.982-11.55-20.241-2.5238-1.4913-5.0573-1.7477-6.7713-1.0076z",
            fill: "#E3DDED"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "m14.646 0.76151c-3.6002 1.5545-5.7108 5.2672-5.7108 10.635 0-10.635 7.9842-12.181 15.502-7.8402 6.3583 3.6716 12.445 10.043 12.445 22.462v11.756c1.2058 0 2.1833-0.9775 2.1833-2.1833v-9.5732c0-11.063-5.2562-19.013-13.695-24-3.2822-1.9395-7.319-2.7273-10.724-1.2572z",
            "clip-rule": "evenodd",
            fill: "#FAF8FF",
            "fill-rule": "evenodd"
          }
        })
      ]),
      _c("defs", [
        _c(
          "filter",
          {
            attrs: {
              id: "a",
              x: "14.498",
              y: "41.534",
              width: "9.1127",
              height: "12.644",
              "color-interpolation-filters": "sRGB",
              filterUnits: "userSpaceOnUse"
            }
          },
          [
            _c("feFlood", {
              attrs: { "flood-opacity": "0", result: "BackgroundImageFix" }
            }),
            _c("feBlend", {
              attrs: {
                in: "SourceGraphic",
                in2: "BackgroundImageFix",
                result: "shape"
              }
            }),
            _c("feColorMatrix", {
              attrs: {
                in: "SourceAlpha",
                result: "hardAlpha",
                values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              }
            }),
            _c("feOffset", { attrs: { dx: "3.49327", dy: "-1.74663" } }),
            _c("feGaussianBlur", { attrs: { stdDeviation: "2.18329" } }),
            _c("feComposite", {
              attrs: {
                in2: "hardAlpha",
                k2: "-1",
                k3: "1",
                operator: "arithmetic"
              }
            }),
            _c("feColorMatrix", {
              attrs: { values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" }
            }),
            _c("feBlend", {
              attrs: { in2: "shape", result: "effect1_innerShadow" }
            })
          ],
          1
        ),
        _c("clipPath", { attrs: { id: "b" } }, [
          _c("rect", { attrs: { width: "48", height: "71", fill: "#fff" } })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-card-input" }, [
    _c(
      "div",
      {
        staticClass: "form-card-input__field",
        class: { "form-card-input__field--error": _vm.displayError },
        attrs: { "data-test-id": "form-card-input-field" }
      },
      [
        _c(
          "label",
          { staticClass: "form-card-input__label form-card-input__number" },
          [
            _c("input", {
              ref: "cardNumber",
              staticClass: "field__number",
              attrs: {
                "data-test-id": "form-card-input-field-number",
                "data-private": "",
                type: "text",
                pattern: "[0-9 ]*",
                placeholder: " ",
                inputmode: "numeric"
              },
              domProps: { value: _vm.form.cardNumber },
              on: { focus: _vm.setFocus, blur: _vm.removeFocus }
            }),
            _c("span", { staticClass: "field__label" }, [
              _vm._v(_vm._s(_vm.$t("form.card.field.number")))
            ]),
            _c("span", {
              class: "credit-card credit-card--" + _vm.creditCardIcon
            })
          ]
        ),
        _c("div", { staticClass: "form-card-input__inline-display" }, [
          _c(
            "label",
            { staticClass: "form-card-input__label form-card-input__exp" },
            [
              _c("input", {
                ref: "expiration",
                staticClass: "field__exp",
                attrs: {
                  "data-test-id": "form-card-input-field-exp",
                  "data-private": "",
                  type: "text",
                  pattern: "[0-9\\/]*",
                  placeholder: " ",
                  inputmode: "numeric"
                },
                domProps: { value: _vm.form.expiration },
                on: { focus: _vm.setFocus, blur: _vm.removeFocus }
              }),
              _c("span", { staticClass: "field__label" }, [
                _vm._v(_vm._s(_vm.$t("form.card.field.exp")))
              ])
            ]
          ),
          _c("label", { staticClass: "form-card-input__label" }, [
            _c("input", {
              ref: "security",
              staticClass: "field__cvc",
              attrs: {
                "data-test-id": "form-card-input-field-cvc",
                "data-private": "",
                type: "text",
                pattern: "[0-9]*",
                placeholder: " ",
                inputmode: "numeric"
              },
              domProps: { value: _vm.form.security },
              on: { focus: _vm.setFocus, blur: _vm.removeFocus }
            }),
            _c("span", { staticClass: "field__label" }, [
              _vm._v(_vm._s(_vm.$t("form.card.field.cvc")))
            ])
          ])
        ])
      ]
    ),
    _vm.displayError
      ? _c(
          "span",
          {
            staticClass: "form-card-input__error-message",
            attrs: { "data-test-id": "form-card-input-error" }
          },
          [_vm._v(" " + _vm._s(_vm.$t(_vm.error)) + " ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c("CheckoutPrice", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showDiscountedAmount,
            expression: "showDiscountedAmount"
          }
        ],
        staticClass: "discountable-price__original",
        attrs: {
          amount: _vm.amount,
          currency: _vm.currency,
          variant: "subdued"
        }
      }),
      _vm.isFlat
        ? _c("CheckoutPrice", {
            staticClass: "discountable-price__total",
            attrs: {
              amount: _vm.amountPrice,
              "main-text-color": _vm.mainTextColor,
              currency: _vm.currency
            }
          })
        : _c(
            "AppTag",
            {
              staticClass: "discountable-price__total",
              attrs: {
                "main-text-color": _vm.mainTextColor,
                variant: _vm.variant
              }
            },
            [
              _c("CheckoutPrice", {
                attrs: {
                  "main-text-color": _vm.mainTextColor,
                  amount: _vm.amountPrice,
                  currency: _vm.currency
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LayoutModal",
    {
      staticClass: "warning-message",
      attrs: { "has-overlay": true, "has-close-btn": false },
      on: { close: _vm.onStay }
    },
    [
      _c("div", { staticClass: "warning-message__logo" }, [
        _vm.siteLogo
          ? _c("img", { attrs: { src: _vm.siteLogo, alt: "logo" } })
          : _vm._e()
      ]),
      _c("div", { staticClass: "warning-message__content" }, [
        _vm.message.title
          ? _c("h2", { staticClass: "content__title" }, [
              _vm._v(" " + _vm._s(_vm.message.title) + " ")
            ])
          : _vm._e(),
        _vm.message.description
          ? _c("p", { staticClass: "content__text" }, [
              _vm._v(" " + _vm._s(_vm.message.description) + " ")
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "warning-message__actions" },
        [
          _c(
            "AppButton",
            {
              staticClass: "actions__primary",
              attrs: { type: "depressed" },
              on: { click: _vm.onStay }
            },
            [
              _vm.isMobile
                ? [_vm._v(_vm._s(_vm.$t("dontLeave.stay.mobile")))]
                : [_vm._v(_vm._s(_vm.$t("dontLeave.stay.desktop")))]
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
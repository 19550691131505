var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "discount" },
    [
      _c("IconTags", { staticClass: "discount__icon" }),
      _c(
        "div",
        { staticClass: "discount__display" },
        [
          _c("i18n", {
            attrs: { path: "checkoutLink.discount.flat" },
            scopedSlots: _vm._u([
              {
                key: "savings",
                fn: function() {
                  return [
                    _c("span", { staticClass: "display__text" }, [
                      _vm._v(_vm._s(_vm.savings))
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
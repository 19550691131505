var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-display" }, [
    _c(
      "div",
      { staticClass: "modal-display__container" },
      [
        !_vm.isPreRegistration
          ? _c("AppIconButton", {
              staticClass: "container__close",
              attrs: { size: "large", name: "times" },
              nativeOn: {
                click: function($event) {
                  return _vm.onClose.apply(null, arguments)
                }
              }
            })
          : _vm._e(),
        _c("div", { staticClass: "container__main" }, [
          _c("div", { staticClass: "main__content" }, [
            _c(
              "div",
              { staticClass: "content__header" },
              [
                _vm.siteLogo
                  ? _c("img", {
                      staticClass: "header__logo",
                      attrs: { alt: "logo", src: _vm.siteLogo }
                    })
                  : _vm._e(),
                !_vm.isPreRegistration
                  ? _c(
                      "AppLink",
                      {
                        staticClass: "header__close",
                        attrs: {
                          "data-test-id": "header-close",
                          "icon-placement": "left"
                        },
                        on: { click: _vm.onClose }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("checkoutLink.back.bundle")) + " "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "content__slot" }, [_vm._t("default")], 2)
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
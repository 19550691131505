var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bracketsToDisplay
    ? _c(
        "div",
        { staticClass: "discount" },
        [
          _c("IconTags", { staticClass: "discount__icon" }),
          _c(
            "div",
            { staticClass: "discount__display" },
            [
              _vm.actualBracket && _vm.actualBracket.isLast
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("checkoutLink.discount.lastBracket")) +
                        " "
                    ),
                    _c("span", { staticClass: "display__text" }, [
                      _vm._v(_vm._s(_vm.actualBracket.currentSavings))
                    ])
                  ])
                : _vm._e(),
              _vm.actualBracket.amount > 0 && !_vm.actualBracket.isLast
                ? _c("i18n", {
                    staticClass: "display__actual",
                    attrs: { path: "checkoutLink.discount.actualBracket" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "actualSavings",
                          fn: function() {
                            return [
                              _c("span", { staticClass: "display__text" }, [
                                _vm._v(_vm._s(_vm.actualBracket.currentSavings))
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      942282949
                    )
                  })
                : _vm._e(),
              _vm.nextBracket && _vm.nextBracket.amount > 0
                ? _c("i18n", {
                    staticClass: "display__next",
                    attrs: { path: "checkoutLink.discount.nextBracket" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "nextLimit",
                          fn: function() {
                            return [
                              _c("span", { staticClass: "display__text" }, [
                                _vm._v(_vm._s(_vm.nextBracket.formattedLimit))
                              ])
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "nextSavings",
                          fn: function() {
                            return [
                              _c("span", { staticClass: "display__text" }, [
                                _vm._v(_vm._s(_vm.nextBracket.nextSavings))
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      865181592
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
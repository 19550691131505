import { render, staticRenderFns } from "./PostSellContent.vue?vue&type=template&id=2ab3f0ea&scoped=true&"
import script from "./PostSellContent.vue?vue&type=script&lang=js&"
export * from "./PostSellContent.vue?vue&type=script&lang=js&"
import style0 from "./PostSellContent.vue?vue&type=style&index=0&id=2ab3f0ea&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ab3f0ea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ab3f0ea')) {
      api.createRecord('2ab3f0ea', component.options)
    } else {
      api.reload('2ab3f0ea', component.options)
    }
    module.hot.accept("./PostSellContent.vue?vue&type=template&id=2ab3f0ea&scoped=true&", function () {
      api.rerender('2ab3f0ea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/checkout-link/views/post-sell-content/PostSellContent.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "post-sell-content", class: _vm.postSellContentClasses },
    [
      _vm.getFunnelContent
        ? _c("ContentBlockReadOnly", {
            attrs: { content: _vm.getFunnelContent }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
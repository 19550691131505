var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-credit-card",
      attrs: {
        width: "16",
        height: "13",
        viewBox: "0 0 16 13",
        fill: "currentColor",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M14 0.625H1.75C0.765625 0.625 0 1.41797 0 2.375V3.25H15.75V2.375C15.75 1.41797 14.957 0.625 14 0.625ZM0 11.125C0 12.1094 0.765625 12.875 1.75 12.875H14C14.957 12.875 15.75 12.1094 15.75 11.125V5.875H0V11.125ZM5.22266 9.59375C5.22266 9.48438 5.30469 9.375 5.44141 9.375H9.35156C9.46094 9.375 9.57031 9.48438 9.57031 9.59375L9.625 10.0312C9.625 10.168 9.51562 10.25 9.40625 10.25H5.46875C5.33203 10.25 5.25 10.168 5.25 10.0312L5.22266 9.59375ZM1.72266 9.59375C1.72266 9.48438 1.80469 9.375 1.94141 9.375H4.12891C4.23828 9.375 4.34766 9.48438 4.34766 9.59375L4.375 10.0312C4.375 10.168 4.26562 10.25 4.15625 10.25H1.96875C1.83203 10.25 1.75 10.168 1.75 10.0312L1.72266 9.59375Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "gallery", staticClass: "app-gallery" },
    [
      _vm.preview
        ? _c("LayoutOverlay", { staticClass: "app-gallery__preview" }, [
            _c(
              "span",
              { staticClass: "container__close" },
              [
                _c("AppIconButton", {
                  staticClass: "button",
                  attrs: { size: "large", name: "times" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.togglePreview.apply(null, arguments)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "masonry" },
              _vm._l(_vm.images, function(image, idx) {
                return _c(
                  "div",
                  {
                    key: "preview-" + idx,
                    staticClass: "masonry__item",
                    attrs: { "data-test-id": "masonry-item" }
                  },
                  [
                    _c("ProductOptimizedImage", {
                      attrs: {
                        "data-test-id": "product-optimized-image",
                        alt: "product image in gallery",
                        src: image,
                        height: _vm.imageHeight,
                        width: _vm.imageWidth
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "app-gallery__slider",
          style: { maxWidth: _vm.galleryWidth }
        },
        [
          _vm.noImage
            ? _c("div", { staticClass: "slider__empty" }, [
                _vm._v(" " + _vm._s(_vm.$t("checkout.image.empty")) + " ")
              ])
            : [
                _c("div", { ref: "slides", staticClass: "slider__slides" }, [
                  _c(
                    "article",
                    {
                      key: "image-0",
                      ref: "image-0",
                      staticClass: "slides__article",
                      attrs: { "data-test-id": "slides-article" },
                      on: { click: _vm.onImageClick }
                    },
                    [
                      _c("ProductOptimizedImage", {
                        attrs: {
                          "data-test-id": "product-optimized-image",
                          alt: "product image in gallery",
                          src: _vm.firstImage,
                          height: _vm.imageHeight,
                          width: _vm.imageWidth
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
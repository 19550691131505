var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkout-link-layout-wrap" },
    [
      _vm.showWarning && !_vm.acquireWarningSeen
        ? _c("WarningMessage", {
            attrs: { "site-logo": _vm.siteLogo, message: _vm.warningMessage },
            on: { stay: _vm.hideWarning }
          })
        : _vm._e(),
      _vm.hasCheckoutLink
        ? _c("CheckoutLinkLayout", {
            class: {
              overlay: _vm.overlay,
              "with-footer": _vm.displayBottomBar
            },
            attrs: {
              "data-test-id": "checkout-link-layout",
              "is-blur": _vm.isBlur
            },
            scopedSlots: _vm._u(
              [
                _vm.overlay
                  ? {
                      key: "overlay",
                      fn: function() {
                        return [_c("router-view")]
                      },
                      proxy: true
                    }
                  : null,
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "checkout-link-page",
                          class: { blur: _vm.isBlur }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "checkout-link-page__cart" },
                            [
                              _c("div", { staticClass: "checkout-link" }, [
                                _c(
                                  "div",
                                  { staticClass: "checkout-link__container" },
                                  [
                                    _c("div", {
                                      ref: "wrapper",
                                      staticClass: "is-wrapper",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getTemplateWithProducts
                                        )
                                      }
                                    })
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm.isPresell ? _c("PreSellDisplay") : _vm._e(),
                          _vm.isAuthView
                            ? _c(
                                "AuthDisplay",
                                { on: { close: _vm.closeModal } },
                                [_c("router-view")],
                                1
                              )
                            : _vm._e(),
                          _vm.insideModal
                            ? _c(
                                "ModalDisplay",
                                {
                                  attrs: { "data-cy": "modal-display" },
                                  on: { close: _vm.closeModal }
                                },
                                [_c("router-view")],
                                1
                              )
                            : _vm._e(),
                          _vm.displayBottomBar
                            ? _c("BottomBar", {
                                attrs: { "data-cy": "bottom-bar" }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
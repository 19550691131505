var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "bottomBar",
      staticClass: "bottom-bar bottom-bar--pre-registration bottom-bar--loaded",
      style: {
        "--bg-alpha-color": _vm.alphaColor,
        display: _vm.displayBottomBar
      }
    },
    [
      _c("p", { staticClass: "bottom-bar__pre-registration" }, [
        _vm._v(" " + _vm._s(_vm.$t("preRegistration.button.help")) + " ")
      ]),
      _c(
        "AppButton",
        {
          staticClass: "bottom-bar__action",
          attrs: {
            type: "primary",
            "data-test-id": "bottom-bar-action-button"
          },
          on: { click: _vm.gotToPreRegistration }
        },
        [_vm._v(" " + _vm._s(_vm.actionText) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subscription-info-card" }, [
    _c(
      "div",
      { staticClass: "subscription-info-card__title" },
      [
        _c("IconTruck", { staticClass: "title__icon" }),
        _c("p", { staticClass: "title__text" }, [_vm._v(_vm._s(_vm.title))])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "subscription-info-card__content" },
      [
        _vm.isInCart
          ? _c("i18n", {
              attrs: { path: _vm.contentKey },
              scopedSlots: _vm._u(
                [
                  {
                    key: "frequency",
                    fn: function() {
                      return [_vm._v(_vm._s(_vm.formattedFrequency.name))]
                    },
                    proxy: true
                  },
                  {
                    key: "amount",
                    fn: function() {
                      return [
                        _c("CheckoutPrice", {
                          attrs: { amount: _vm.amount, currency: _vm.currency }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "futureAmount",
                    fn: function() {
                      return [
                        _c("CheckoutPrice", {
                          attrs: {
                            amount: _vm.futureAmount,
                            currency: _vm.currency
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                868871567
              )
            })
          : [
              _c(
                "a",
                {
                  staticClass: "content__policy",
                  attrs: {
                    rel: "noopener noreferrer",
                    target: "_blank",
                    href:
                      "https://help.acquire.app/en/articles/6263213-subscription-management"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "checkout.purchaseOption.subscription.details.policy"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
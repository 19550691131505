var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-input" }, [
    _c(
      "label",
      {
        staticClass: "form-input__label",
        attrs: { "data-test-id": "form-input-label" }
      },
      [
        _c("input", {
          staticClass: "form-input__field",
          class: { "form-input__field--error": _vm.displayError },
          attrs: {
            "data-test-id": "form-input-field",
            placeholder: " ",
            disabled: _vm.disabled,
            required: _vm.required,
            type: _vm.type,
            maxlength: _vm.maxLength
          },
          domProps: { value: _vm.value },
          on: {
            change: _vm.onChange,
            input: _vm.onInput,
            focus: _vm.onFocus,
            blur: _vm.onBlur
          }
        }),
        _c("span", { staticClass: "field__label" }, [_vm._v(_vm._s(_vm.label))])
      ]
    ),
    _vm.displayError
      ? _c(
          "span",
          {
            staticClass: "form-input__error-message",
            attrs: { "data-test-id": "form-input-error" }
          },
          [_vm._v(" " + _vm._s(_vm.error) + " ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
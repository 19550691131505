var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "confirmation" }, [
    _c("div", { staticClass: "confirmation__wrapper" }, [
      _c("div", { staticClass: "confirmation__header" }, [
        _vm.siteLogo
          ? _c("img", {
              staticClass: "confirmation__logo",
              attrs: { alt: "Logo", src: _vm.siteLogo }
            })
          : _vm._e(),
        _vm.order.number
          ? _c("div", [_vm._v("#" + _vm._s(_vm.order.number))])
          : _vm._e()
      ]),
      _c("div", { staticClass: "confirmation__card" }, [
        _c(
          "div",
          { staticClass: "confirmation__success" },
          [
            _c("IconVerified", { staticClass: "heading__icon" }),
            _c("div", { staticClass: "heading__title" }, [
              _vm._v(_vm._s(_vm.$t("order.title")))
            ]),
            _vm.order.email
              ? _c("div", { staticClass: "heading__description" }, [
                  _vm._v(" " + _vm._s(_vm.$t("order.description")) + " "),
                  _c("span", { staticClass: "email" }, [
                    _vm._v(_vm._s(_vm.order.email))
                  ])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "heading__price" },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$tc("order.itemCount", _vm.order.items.length, {
                        n: _vm.order.items.length
                      })
                    )
                  )
                ]),
                _vm._v(" • "),
                _vm.order.addedPrice
                  ? _c(
                      "AppTag",
                      [
                        _c("CheckoutPrice", {
                          attrs: {
                            amount: _vm.order.addedPrice,
                            currency: _vm.order.currency,
                            prefix: "+ "
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c("CheckoutPrice", {
                  staticClass: "total__amount",
                  attrs: {
                    amount: _vm.order.total,
                    currency: _vm.order.currency
                  }
                })
              ],
              1
            ),
            _vm.showPostSell
              ? _c(
                  "div",
                  { staticClass: "confirmation__post-sell" },
                  [
                    _c("PostSellContent", {
                      staticClass: "post-sell__content"
                    }),
                    _c("PostSellOffer", {
                      attrs: { "inside-modal": _vm.insideModal }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm.order.address
          ? _c(
              "div",
              { staticClass: "confirmation__shipping confirmation__section" },
              [
                _c("div", { staticClass: "confirmation__section-label" }, [
                  _vm._v(" " + _vm._s(_vm.$t("order.shipping")) + " ")
                ]),
                _c("div", { staticClass: "confirmation__address" }, [
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.order.address.firstname) +
                        " " +
                        _vm._s(_vm.order.address.lastname)
                    )
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.order.address.line1))]),
                  _c("div", [_vm._v(_vm._s(_vm.order.address.line2))]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.order.address.city) +
                        " " +
                        _vm._s(_vm.order.address.province) +
                        " " +
                        _vm._s(_vm.order.address.zip)
                    )
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.order.address.country))])
                ])
              ]
            )
          : _vm._e(),
        _vm.order.payment.name
          ? _c(
              "div",
              { staticClass: "confirmation__payment confirmation__section" },
              [
                _c("div", { staticClass: "confirmation__section-label" }, [
                  _vm._v(" " + _vm._s(_vm.$t("order.payment")) + " ")
                ]),
                _c("div", { staticClass: "confirmation__payment-method" }, [
                  _vm.order.payment.isLink
                    ? _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("checkout.stripeDefaultPaymentMethod")
                          )
                        }
                      })
                    : _vm.order.payment.isAfterpay
                    ? _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("checkout.afterpay"))
                        }
                      })
                    : _c("span", {
                        class:
                          "credit-card credit-card--" + _vm.order.payment.icon
                      }),
                  _vm._v(" " + _vm._s(_vm.order.payment.identifier) + " ")
                ])
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "confirmation__details confirmation__section" },
          [
            _c("div", { staticClass: "confirmation__section-label" }, [
              _vm._v(" " + _vm._s(_vm.$t("order.details")) + " ")
            ]),
            _c("CheckoutProductListRemovable", {
              staticClass: "items__removable",
              attrs: { items: _vm.order.items }
            })
          ],
          1
        ),
        _vm.subscriptionItems.length
          ? _c(
              "div",
              {
                staticClass: "confirmation__subscriptions confirmation__section"
              },
              [
                _c("div", { staticClass: "confirmation__section-label" }, [
                  _vm._v(" " + _vm._s(_vm.$t("order.subscription")) + " ")
                ]),
                _c("CheckoutProductListRemovable", {
                  staticClass: "items__removable",
                  attrs: { items: _vm.subscriptionItems }
                }),
                _vm.order.frequency
                  ? _c("SubscriptionInfoCard", {
                      staticClass: "products__info-card",
                      attrs: {
                        "is-in-cart": true,
                        frequency: _vm.order.frequency,
                        "future-amount": _vm.order.renewalAmount,
                        currency: _vm.order.currency,
                        title: _vm.$t("order.subscriptionInfoTitle"),
                        "content-key": "order.subscriptionInfoContent"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "confirmation__total-amounts confirmation__section" },
          [
            _c("div", { staticClass: "total-amounts__detail" }, [
              _c("div", { staticClass: "detail__label" }, [
                _vm._v(_vm._s(_vm.$t("checkout.price")))
              ]),
              _c(
                "div",
                { staticClass: "detail__price" },
                [
                  _c("CheckoutPrice", {
                    attrs: {
                      amount: _vm.order.price,
                      currency: _vm.order.currency
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "total-amounts__detail" }, [
              _c("div", { staticClass: "detail__label" }, [
                _vm._v(_vm._s(_vm.$t("checkout.taxes")))
              ]),
              _c(
                "div",
                { staticClass: "detail__price" },
                [
                  _c("CheckoutPrice", {
                    attrs: {
                      amount: _vm.order.taxes,
                      currency: _vm.order.currency
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "total-amounts__detail" }, [
              _c("div", { staticClass: "detail__label" }, [
                _vm._v(_vm._s(_vm.$t("checkout.shipping")))
              ]),
              _c(
                "div",
                { staticClass: "detail__price" },
                [
                  _c("CheckoutPrice", {
                    attrs: {
                      amount: _vm.order.shipping,
                      currency: _vm.order.currency
                    }
                  })
                ],
                1
              )
            ]),
            _vm.order.discount !== 0
              ? _c("div", { staticClass: "total-amounts__detail" }, [
                  _c("div", { staticClass: "detail__label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("checkoutLink.discount.discountTitle"))
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "detail__price" },
                    [
                      _c("CheckoutPrice", {
                        attrs: {
                          amount: _vm.order.discount,
                          currency: _vm.order.currency
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "total-amounts__total" },
              [
                _c("div", { staticClass: "total__label" }, [
                  _vm._v(_vm._s(_vm.$t("checkout.total")))
                ]),
                _c("CheckoutDiscountablePrice", {
                  staticClass: "total__price",
                  attrs: {
                    amount: _vm.order.total,
                    currency: _vm.order.currency,
                    variant: "flat"
                  }
                })
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "a",
        {
          staticClass: "confirmation__manage-orders",
          attrs: {
            href: _vm.manageOrdersLink,
            target: "_blank",
            rel: "noopener noreferrer"
          }
        },
        [_vm._v(_vm._s(_vm.$t("order.manage")))]
      ),
      _c("div", { staticClass: "confirmation__footer" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "landline", attrs: { "data-test-id": "landline-phone" } },
    [
      _c("h1", { staticClass: "landline__title" }, [
        _vm._v(" " + _vm._s(_vm.$t("authentication.landline.title")) + " ")
      ]),
      _c("h2", { staticClass: "landline__subtitle" }, [
        _vm._v(" " + _vm._s(_vm.$t("authentication.landline.subtitle")) + " ")
      ]),
      _c("UserPhone", {
        staticClass: "landline__phone",
        attrs: {
          disabled: _vm.isVerifyingOtpAccount,
          "default-country": _vm.defaultCountry
        },
        on: { "phone-changed": _vm.handlePhoneChange }
      }),
      _c(
        "AppButton",
        {
          staticClass: "landline__button",
          attrs: { disabled: !_vm.canSubmit },
          on: { click: _vm.handleSubmit }
        },
        [_vm._v(" " + _vm._s(_vm.$t("authentication.landline.action")) + " ")]
      ),
      _vm.hasError
        ? _c("div", { staticClass: "landline__error" }, [
            _vm._v(" " + _vm._s(_vm.$t("error.phonenumber.exist")) + " ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
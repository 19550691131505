var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "funnel-counter" },
    [
      _c("div", { staticClass: "funnel-counter__label" }, [
        _vm._v(_vm._s(_vm.$t("cart.upsell.label")) + ":")
      ]),
      _c("AppCounter", {
        staticClass: "funnel-counter__counter",
        attrs: {
          "initial-duration": _vm.initialDuration,
          "external-duration": _vm.externalDuration,
          "internal-timer": _vm.internalTimer
        },
        on: { elapsed: _vm.onElapsed }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasOverlay
      ? _c("div", { staticClass: "layout-modal-overlay" })
      : _vm._e(),
    _c("div", { staticClass: "layout-modal" }, [
      _c(
        "div",
        { staticClass: "layout-modal__container" },
        [
          _vm.hasCloseBtn
            ? _c(
                "div",
                { staticClass: "container__close" },
                [
                  _c("AppIconButton", {
                    staticClass: "button",
                    attrs: { size: "large", name: "times" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onClose.apply(null, arguments)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._t("default")
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "layout-brand",
      attrs: {
        target: "_blank",
        rel: "noopener noreferrer",
        href:
          "http://acquire.app/?utm_source=checkoutlinks&utm_content=" +
          _vm.siteName
      },
      on: { click: _vm.onClick }
    },
    [
      _c("span", {
        staticClass: "layout-brand__img",
        attrs: { "data-test-id": "layout-brand-img" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
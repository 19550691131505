var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.buttonClass,
      attrs: { type: "button", disabled: _vm.disabled },
      on: { click: _vm.onClick }
    },
    [
      _vm.iconPrefix
        ? _c("font-awesome-icon", {
            staticClass: "button__icon-prefix",
            attrs: { icon: _vm.iconPrefix, "data-test-id": "icon-prefix" }
          })
        : _vm._e(),
      _vm._t("default"),
      _vm.iconSuffix
        ? _c("font-awesome-icon", {
            staticClass: "button__icon-suffix",
            attrs: { icon: _vm.iconSuffix, "data-test-id": "icon-suffix" }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkout-link-exception" }, [
    _c("div", { staticClass: "checkout-link-exception__card" }, [
      _vm.isOpenedBoxIcon
        ? _c("div", { staticClass: "card__icon-box" }, [_c("IconOpenedBox")], 1)
        : _vm.isClosedBoxIcon
        ? _c("div", { staticClass: "card__icon-box" }, [_c("IconClosedBox")], 1)
        : _vm.isClosedSign
        ? _c(
            "div",
            { staticClass: "card__icon-sign" },
            [_c("IconClosedSign")],
            1
          )
        : _c("div", { staticClass: "card__icon-lock" }, [_c("IconLock")], 1),
      _vm.getExceptionPageMessage
        ? _c("div", { staticClass: "card__text" }, [
            _vm.firstname
              ? _c("div", { staticClass: "text__title" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.getExceptionPageMessage + ".title.logged", {
                          name: _vm.firstname
                        })
                      ) +
                      " "
                  )
                ])
              : _c("div", { staticClass: "text__title" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.getExceptionPageMessage + ".title.unlogged")
                      ) +
                      " "
                  )
                ]),
            _c("div", { staticClass: "text__description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(_vm.getExceptionPageMessage + ".description", {
                      max: _vm.getCheckoutLinkMaxOrderAmount
                    })
                  ) +
                  " "
              )
            ])
          ])
        : _vm._e(),
      _vm.isNotOpenedYetException && !_vm.isOverException
        ? _c(
            "div",
            { staticClass: "card__countdown" },
            [
              _c("CountDown", {
                attrs: { date: _vm.getCheckoutLinkStartDate },
                on: { done: _vm.reloadPage }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card-select",
      class: [_vm.isMaterial ? "card-select--material" : "card-select--basic"]
    },
    [
      _vm.isMaterial
        ? _c("span", { staticClass: "field__label" }, [
            _vm._v(" " + _vm._s(_vm.label) + " ")
          ])
        : _vm._e(),
      _c("VSelect", {
        ref: "select",
        attrs: {
          options: _vm.options,
          reduce: function(obj) {
            return obj.value
          },
          label: "name",
          disabled: _vm.disabled,
          "append-to-body": "",
          "calculate-position": _vm.calculatePosition,
          searchable: false,
          clearable: false
        },
        on: { input: _vm.emitValueChange },
        scopedSlots: _vm._u(
          [
            {
              key: "selected-option",
              fn: function(option) {
                return [
                  _vm.isMaterial && !option.isLink
                    ? _c("span", {
                        class: "card-select__card credit-card--" + option.type
                      })
                    : _vm._e(),
                  option.isLink
                    ? _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("checkout.stripeDefaultPaymentMethod")
                          )
                        }
                      })
                    : _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.maskNumbers ? "**** **** ****" : option.name
                            ) +
                            " " +
                            _vm._s(option.last) +
                            " "
                        )
                      ])
                ]
              }
            },
            {
              key: "option",
              fn: function(option) {
                return [
                  !option.isLink
                    ? _c("span", {
                        class: "credit-card credit-card--" + option.type
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "card-select__options" }, [
                    option.isLink
                      ? _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("checkout.stripeDefaultPaymentMethod")
                            )
                          }
                        })
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.maskNumbers ? "**** **** ****" : option.name
                              ) +
                              " " +
                              _vm._s(option.last) +
                              " "
                          )
                        ])
                  ]),
                  _c("font-awesome-icon", {
                    staticClass: "card-select__check",
                    attrs: { icon: "check" }
                  })
                ]
              }
            },
            _vm.addOption
              ? {
                  key: "list-footer",
                  fn: function() {
                    return [
                      _c("li", [
                        _c(
                          "div",
                          { staticClass: "card-select__add" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "plus" }
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "add__icon",
                                on: { click: _vm.emitAddOption }
                              },
                              [_vm._v(_vm._s(_vm.addOptionLabel))]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedValue,
          callback: function($$v) {
            _vm.selectedValue = $$v
          },
          expression: "selectedValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-with-products" }, [
    _vm.hasOneTimeItem
      ? _c(
          "div",
          { staticClass: "products__one-time" },
          [
            _c("div", { staticClass: "cart-with-products__title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$tc("cart.products", _vm.getOneTimeItems.length)) +
                  " "
              ),
              _vm.hasMultipleOneTimeItems
                ? _c("span", [
                    _vm._v(" (" + _vm._s(_vm.getOneTimeItems.length) + ") ")
                  ])
                : _vm._e()
            ]),
            _vm._l(_vm.getOneTimeItems, function(product) {
              return _c("CheckoutProductInCart", {
                key: product._id,
                staticClass: "cart-with-products__product",
                attrs: {
                  product: product,
                  "qty-total": _vm.getItemTotal(product.productId),
                  "can-delete": _vm.canDelete(product)
                },
                on: {
                  "quantity-changed": _vm.onUpdateItemQuantity,
                  delete: _vm.onDeleteItem
                }
              })
            })
          ],
          2
        )
      : _vm._e(),
    _vm.hasGiftItem
      ? _c(
          "div",
          { staticClass: "products__one-time" },
          [
            _c("div", { staticClass: "cart-with-products__title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$tc("cart.gifts", _vm.getGiftItems.length)) +
                  " "
              ),
              _vm.hasMultipleGiftItems
                ? _c("span", [
                    _vm._v(" (" + _vm._s(_vm.getGiftItems.length) + ") ")
                  ])
                : _vm._e()
            ]),
            _vm._l(_vm.getGiftItems, function(product) {
              return _c("CheckoutProductInCart", {
                key: product._id,
                staticClass: "cart-with-products__product",
                attrs: {
                  product: product,
                  "qty-total": _vm.getItemTotal(product.productId),
                  "can-delete": _vm.canDelete(product)
                },
                on: {
                  "quantity-changed": _vm.onUpdateItemQuantity,
                  delete: _vm.onDeleteItem
                }
              })
            })
          ],
          2
        )
      : _vm._e(),
    _vm.hasSubscriptionItem
      ? _c(
          "div",
          { staticClass: "products__subscription" },
          [
            _c("div", { staticClass: "cart-with-products__title" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$tc(
                      "cart.subscriptions",
                      _vm.getSubscriptionItems.length
                    )
                  ) +
                  " "
              ),
              _vm.hasMultipleSubscriptionItems
                ? _c("span", [
                    _vm._v(
                      " (" + _vm._s(_vm.getSubscriptionItems.length) + ") "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._l(_vm.getSubscriptionItems, function(product) {
              return _c("CheckoutProductInCart", {
                key: product._id,
                staticClass: "cart-with-products__product",
                attrs: {
                  product: product,
                  "qty-total": _vm.getItemTotal(product.productId),
                  "can-delete": _vm.canDelete(product)
                },
                on: {
                  "quantity-changed": _vm.onUpdateItemQuantity,
                  delete: _vm.onDeleteItem
                }
              })
            }),
            _vm.displaySettings
              ? _c("div", [_c("SubscriptionSettings")], 1)
              : _vm._e(),
            _c("SubscriptionInfoCard", {
              staticClass: "products__info-card",
              attrs: {
                "is-in-cart": true,
                frequency: _vm.getSelectedFrequency,
                amount: _vm.getTotalDiscountedCart,
                "future-amount": _vm.getTotalSubscriptions,
                currency: _vm.getCurrency,
                title: _vm.$t(
                  "checkout.purchaseOption.subscription.details.title"
                ),
                "content-key":
                  "checkout.purchaseOption.subscription.details.contentAmount"
              }
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
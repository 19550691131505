var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-cart",
      attrs: {
        width: "13",
        height: "15",
        viewBox: "0 0 13 15",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M10 5.125V4.25C10 2.33594 8.41406 0.75 6.5 0.75C4.55859 0.75 3 2.33594 3 4.25V5.125H0.375V12.5625C0.375 13.793 1.33203 14.75 2.5625 14.75H10.4375C11.6406 14.75 12.625 13.793 12.625 12.5625V5.125H10ZM4.75 4.25C4.75 3.29297 5.51562 2.5 6.5 2.5C7.45703 2.5 8.25 3.29297 8.25 4.25V5.125H4.75V4.25ZM9.125 7.53125C8.74219 7.53125 8.46875 7.25781 8.46875 6.875C8.46875 6.51953 8.74219 6.21875 9.125 6.21875C9.48047 6.21875 9.78125 6.51953 9.78125 6.875C9.78125 7.25781 9.48047 7.53125 9.125 7.53125ZM3.875 7.53125C3.49219 7.53125 3.21875 7.25781 3.21875 6.875C3.21875 6.51953 3.49219 6.21875 3.875 6.21875C4.23047 6.21875 4.53125 6.51953 4.53125 6.875C4.53125 7.25781 4.23047 7.53125 3.875 7.53125Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }